import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { Loading } from "../../components/shared/other/index.js";
import { stripeLoginGet } from "../../services/api/other.js";
import { localStorageDict } from "../../services/enums/index.js";
import { writeStorage } from "../../services/utils/index.js";

export default ({ match }) => {
  const { i18n } = useTranslation();
  const {
    loading, error, payload, query,
  } = useQuery(stripeLoginGet(match.params.accountId, match.params.sessionId));

  useEffect(() => {
    if (!loading && !error && payload) {
      writeStorage(localStorageDict.JWT, payload.data.token);
      writeStorage(localStorageDict.ROLE, payload.data.role);
      writeStorage(localStorageDict.FEATURE_FLAGS, payload.data.featureFlags);
      writeStorage(localStorageDict.LANGUAGE, payload.data.language);

      if (payload.data?.language) {
        i18n.changeLanguage(payload.data.language);
      }
      writeStorage(localStorageDict.TIME_ZONE, payload.data.timeZone);
      writeStorage(localStorageDict.ROLE_BACK, null);
      window.location.href = "/";
    }
    let timeoutId = null;
    if (error) {
      timeoutId = setTimeout(() => query(), 10000);
    }
    return () => clearTimeout(timeoutId);
  }, [loading, error, payload]);

  return (
    <Box>
      <Loading />
    </Box>
  );
};

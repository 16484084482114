import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default React.memo(({ status = "500" }) => {
  const { t } = useTranslation();

  const getMessage = () => {
    switch (parseInt(status, 10)) {
      case 500: return t("generic_error");
      case 401: {
        window.location.href = "/";
        return t("unauthorized");

      }
      case 403: return t("access_denied");
      case 404: return t("not_found");
      default: return t("generic_error");
    }
  };

  return <Alert severity="error">{getMessage()}</Alert>;
});

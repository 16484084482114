import { Box, Container } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom";

import { Footer, Header } from "../../components/shared/other/index.js";
import { profileUrl, rootUrl } from "../../services/routes.js";
import { Error404, Panel, Profile } from "../index.js";

export default React.memo(() => (
  <Box display="flex" flexDirection="column" flexGrow={1}>
    <Header />
    <Box flex="1 0">
      <Container maxWidth="lg" style={{ paddingTop: 30 }}>
        <Switch>
          <Route path={rootUrl()} exact>
            <Panel />
          </Route>
          <Route path={profileUrl()} exact>
            <Profile />
          </Route>
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </Container>
    </Box>
    <Footer />
  </Box>
));

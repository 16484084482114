import { FormControl } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { models } from "../../../../services/enums/index.js";
import { useGenericInputStyle } from "../../../../services/hooks/index.js";
import { SelectTextField } from "../index.js";
import setPlanSelectedData from "./_set-plan-selected-data.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValues, label = "plan", fieldName = "planName", fieldId = "planId", options = {},
}) => {
  // passing label, fieldNanem, fieldId as you may need to change some of it
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <SelectTextField
        model={models.PLAN}
        label={t(label)}
        name={fieldName}
        value={form[fieldName] || ""}
        use={setPlanSelectedData(setFieldValues, fieldName, fieldId)}
        error={isFieldError(fieldId)}
        helperText={fieldErrorMessage(fieldId)}
        options={options}
      />
    </FormControl>
  );
};

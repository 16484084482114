import { OpenInNew } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  link, tooltipTitle = "open_in_a_new_tab", placement = "top", size = "small", disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tooltipTitle)} placement={placement} disableTouchListener>
      <span>
        <IconButton
          disabled={disabled}
          size={size}
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <OpenInNew fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card, CardContent, CardHeader, Stack, Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { stripePlanPaymentLink } from "../../../services/api/plan.js";
import { OpenTabButton, TextCopyButton } from "../../shared/buttons/index.js";
import { PlanField } from "../../shared/fields/index.js";
import { GenericError } from "../../shared/other/index.js";

export default React.memo(() => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState({ planId: null, planName: null });
  const [url, setUrl] = useState(null);
  const {
    loading, error, payload, mutate, status,
  } = useMutation(stripePlanPaymentLink);

  useEffect(() => {
    if (!loading && !error && !_.isNil(payload)) {
      setUrl(payload.paymentLink);
    }
  }, [payload, plan]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardHeader
        title={(
          <Typography variant="h3" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
            {t("generate_payment_link")}
          </Typography>
            )}
      />
      <CardContent>
        <Alert
          severity="info"
          sx={{
            marginBottom: "20px", whiteSpace: "pre-line", width: "100%", wordBreak: "break-word",
          }}
        >
          {t("generate_payment_link_alert_desc")}
        </Alert>
        <PlanField
          form={plan}
          isFieldError={() => false}
          fieldErrorMessage={() => ""}
          setFieldValues={plan => {
            setPlan({ planId: plan.planId, planName: plan.planName });
          }}
          options={{ onlyWithPrice: true }}
        />
        <Stack direction="row" spacing="5px">
          <LoadingButton variant="contained" loading={loading} disabled={!plan.planId} onClick={() => mutate(plan.planId)}>{t("generate")}</LoadingButton>
          <OpenTabButton disabled={!url} link={url} size="medium" />
          <TextCopyButton disabled={!url} textToCopy={url} label="copy_link" size="medium" />
        </Stack>
        <Typography variant="caption">{url}</Typography>
      </CardContent>
    </Card>
  );
});

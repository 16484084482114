import React from "react";

import { createFuncDef } from "../../../services/utils/index.js";
import { PreviewDialogTemplate } from "../../shared/templates/index.js";
import NotificationPanel from "../NotificationPanel/index.js";

export default React.memo(({ actions = {}, older = false }) => (
  <PreviewDialogTemplate
    actions={actions}
    title="notifications"
    maxWidth="sm"
    breakpointPixels={450}
    content={<NotificationPanel older={older} />}
    onCloseCallbacks={[createFuncDef(() => actions.onClose())]}
  />
));

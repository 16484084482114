import { decode } from "jsonwebtoken";
import _ from "lodash";

import { localStorageDict } from "../enums/index.js";
import { useLocalStorage } from "./index.js";

export default () => {
  const [jwt] = useLocalStorage(localStorageDict.JWT, null);
  const [role] = useLocalStorage(localStorageDict.ROLE, null);

  const isAuthenticated = () => {
    if (!_.isNil(jwt)) {
      const decoded = decode(jwt);

      if (decoded && decoded.exp > (new Date().getTime() / 1000) && !_.isNil(role)) {
        return true;
      }
    }

    return false;
  };

  const getUserData = () => (_.isNil(jwt) ? null : decode(jwt));

  return { isAuthenticated, getUserData };
};

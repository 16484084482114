import { Box, colors } from "@mui/material";
import _ from "lodash";
import React from "react";

import { fileBlobGet } from "../../../../services/api/file.js";
import { localStorageDict } from "../../../../services/enums/index.js";
import useLocalStorage from "../../../../services/hooks/use-local-storage.js";

export default React.memo(({ bg, children, width = "100%" }) => {
  const [theme] = useLocalStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);
  return (
    <Box
      width={width}
      height={300}
      style={_.isNil(bg)
        ? { backgroundColor: _.isEqual(theme, localStorageDict.THEME.VALUES.DARK) ? colors.grey[900] : colors.grey[500], borderRadius: 15, position: "relative" }
        : {
          background: `url("${fileBlobGet(bg)}") no-repeat center`, backgroundSize: "cover", borderRadius: 15, position: "relative",
        }}
    >
      {children}
    </Box>
  );
});

import { ContentCopy } from "@mui/icons-material";
import {
  IconButton, ListItemIcon, MenuItem, Tooltip,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmDuplicateFormDialog } from "../../dialogs/index.js";

export default React.memo(({
  modelId, duplicate, childId, removeAnchor = () => ({}), callback, style, placement = "bottom", buttonSize = "medium", isList = false,
}) => {
  const { t } = useTranslation();
  const actions = {};

  return (
    <>
      {isList && (
        <MenuItem onClick={() => {
          removeAnchor();
          actions.open(duplicate, modelId, childId);
        }}
        >
          <ListItemIcon size={buttonSize}>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          {t("create_copy")}
        </MenuItem>
      )}
      {!isList && (
        <Tooltip title={t("create_copy")} placement={placement} disableTouchListener>
          <IconButton
            size={buttonSize}
            onClick={() => {
              removeAnchor();
              actions.open(duplicate, modelId, childId);
            }}
            style={style}
          >
            <ContentCopy fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      <ConfirmDuplicateFormDialog actions={actions} callback={callback} />
    </>
  );
});

import { Score } from "@mui/icons-material";
import {
  Alert, Box, Dialog, DialogContent, DialogTitle, IconButton, List, Tooltip, useMediaQuery, useTheme,
} from "@mui/material";
import {
  addDays, addHours, addMinutes, isAfter, isBefore,
} from "date-fns";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { scoreFilterByEntryGet } from "../../../services/api/score.js";
import { intervals, roles } from "../../../services/enums/index.js";
import { useCommonDateFormats, useOpenCloseControl, useRoleValidator } from "../../../services/hooks/index.js";
import { createFuncDef, setDateByInterval } from "../../../services/utils/index.js";
import {
  BoxMessage, FormDialogActions, GenericError, Loading,
} from "../../shared/other/index.js";
import { StatisticPreviewDialog } from "../../statistic/index.js";
import ItemWithScore from "./_item-with-score.js";
import ItemWithoutScore from "./_item-without-score.js";

export default ({ actions }) => {
  const theme = useTheme();
  const previewActions = {};
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { isOpen, handleOpen, handleClose } = useOpenCloseControl([createFuncDef(() => reset()), createFuncDef(() => setScores([]))]);
  const {
    loading, query, error, status, reset,
  } = useParameterizedQuery(scoreFilterByEntryGet, false);
  const [scores, setScores] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [competitors, setCompetitors] = React.useState([]);
  const [entry, setEntry] = React.useState("");
  const { isRole } = useRoleValidator();
  const [scoreDate, setScoreDate] = React.useState(new Date());
  const { formatWholeDate } = useCommonDateFormats();
  const [plan, setPlan] = React.useState(null);
  const [queryArgs, setQueryArgs] = React.useState([]);
  const [canEdit, setCanEdit] = React.useState(true);
  const [isShared, setIsShared] = React.useState(false);

  const reload = queryArgs => query(...queryArgs).then(res => {
    setScores(res.payload?.scores?.data || []);
    setSubscriptions(res.payload?.subscriptions?.data || []);
    setCompetitors(res.payload?.competitors?.data || []);
  });

  actions.open = (entry, plan, shift = 0, canEdit = true, isShared = false) => {
    handleOpen();
    setEntry(entry);
    setPlan(plan);
    setCanEdit(canEdit);
    setIsShared(isShared);
    let date = new Date();

    if (!_.isNil(plan)) {
      date = addDays(addHours(setDateByInterval(plan.interval, 1, date, 0), _.isNil(plan.scoreShift) ? 0 : plan.scoreShift), shift);
    }
    setScoreDate(date);

    if (isRole(roles.COACH) || isAfter(new Date(), date) || plan.interval !== intervals.COMPETITION) {
      const qa = ["", 1, 1000, "", "", entry._id, date.toISOString()];
      setQueryArgs(qa);
      reload(qa);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xs"
      open={isOpen()}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex">
          <Box flex="1 0" sx={{ wordBreak: "break-word" }}>
            {t("scores")}
            {" "}
            {entry.title}
          </Box>
          {!_.isNil(entry.statisticId) && !isShared && (
          <Box flex="0 1" flexDirection="flex-end">
            <Tooltip title={t("see_statistics")} placement="left" disableTouchListener>
              <IconButton onClick={() => previewActions.open(entry.statisticId)}>
                <Score fontSize="small" />
              </IconButton>
            </Tooltip>
            <StatisticPreviewDialog actions={previewActions} />
          </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading && <Loading />}
        {error && <GenericError status={status} />}

        {!loading && scores.length === 0 && (isAfter(new Date(), scoreDate) || isRole(roles.COACH)) && entry.isScore && <BoxMessage msg={t("no_scores")} />}

        {!loading && scores.length === 0 && !isRole(roles.COACH) && (!_.isNil(plan) && _.isEqual(plan.interval, intervals.COMPETITION)) && <BoxMessage msg={t("scores_not_yet_available")} />}

        {isBefore(new Date(), scoreDate) && !isRole(roles.COACH) && plan.interval !== intervals.COMPETITION &&
        <Alert severity="info">{t("scores_will_show_up_after", { date: formatWholeDate(addMinutes(scoreDate, 1)) })}</Alert>}
        <List>
          {_.map(scores, (item, idx) => (
            <ItemWithScore
              key={item._id}
              item={item}
              entry={entry}
              idx={idx}
              isRole={isRole}
              plan={plan}
              reload={reload}
              queryArgs={queryArgs}
              canEdit={canEdit}
            />
          ))}
          {isRole(roles.COACH) && _.map(subscriptions, (item, idx) => (
            <ItemWithoutScore
              key={idx}
              item={item}
              entry={entry}
              isRole={isRole}
              user={item.customer.user}
              plan={plan}
              reload={reload}
              queryArgs={queryArgs}
              canEdit={canEdit}
            />
          ))}
          {isRole(roles.COACH) && _.map(competitors, (item, idx) => (
            <ItemWithoutScore
              key={idx}
              item={item}
              entry={entry}
              user={item.user}
              plan={plan}
              reload={reload}
              queryArgs={queryArgs}
              canEdit={canEdit}
            />
          ))}
        </List>
      </DialogContent>
      <FormDialogActions close={handleClose} />
    </Dialog>
  );
};

import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import GenericNotificationItem from "./_generic-notification-item.js";

export default memo(({ notif }) => {
  const { t } = useTranslation();

  return (
    <GenericNotificationItem notif={notif} title={t("user_has_signed_up_for_a_competition_x", { plan: notif.metadata.planName })} />
  );
});

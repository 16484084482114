import { Button } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { competitorMeGet } from "../../../services/api/competitor.js";
import { fileBlobGet } from "../../../services/api/file.js";
import { CompetitionSignUpFormDialog } from "../index.js";

export default ({ plan, callback, variant = "text" }) => {
  const { t } = useTranslation();
  const { status, query } = useQuery(competitorMeGet(plan._id));
  const actions = {};

  return (
    <>
      {(status === 404 || status === 401) && <Button variant={variant} onClick={() => actions.create({ planId: plan._id })}>{t("sign_up")}</Button>}
      <CompetitionSignUpFormDialog
        plan={plan}
        termsUrl={_.isNil(plan.terms) ? null : fileBlobGet(plan.terms)}
        actions={actions}
        callback={() => {
          query();

          if (!_.isNil(callback)) {
            callback();
          }
        }}
      />
    </>
  );
};

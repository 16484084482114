import { methods } from "../enums/index.js";
import { apiAction } from "./index.js";

export const statisticDataPost = data => ["/statistic", methods.POST, data];
export const statisticDataPut = data => [`/statistic/${data._id}`, methods.PUT, data];
export const statisticDelete = id => [`/statistic/${id}`, methods.DELETE];
export const statisticOneGet = id => apiAction(`/statistic/one/${id}`);
export const statisticFilterGet = (search = "", page = 1, limit = 100, sort = "-createdAt", filter = "") => apiAction(
  `/statistic/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);

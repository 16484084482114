import React from "react";

import { GenericError } from "../../components/shared/other/index.js";
import { UserProfileCoach, UserProfileCustomer } from "../../components/user/index.js";
import { roles } from "../../services/enums/index.js";
import { useRoleValidator } from "../../services/hooks/index.js";

export default React.memo(() => {
  const { isRole } = useRoleValidator();

  if (isRole(roles.COACH)) {
    return <UserProfileCoach />;
  } else if (isRole(roles.CUSTOMER)) {
    return <UserProfileCustomer />;
  }

  return <GenericError status={401} />;
});

import { fixed, intervals } from "../../../services/enums/index.js";
import calcDailyData from "./_calc-daily-data.js";
import calcMonthlyData from "./_calc-monthly-data.js";
import calcWeeklyData from "./_calc-weekly-data.js";

export default (plan, payload, setData, rows, getLoggedUserLocale) => {
  switch (plan.interval) {
    case intervals.COMPETITION:
    case intervals.STATIC:
      // date is fixed here because it will be always a Day 1 (actually day 2, but first column is always displayed as -1), a starting point
      setData(calcDailyData(payload.entries.data, rows, getLoggedUserLocale(), new Date(fixed.STATIC_BLOCK_START_TIME), true));
      break;
    case intervals.DAILY:
      setData(calcDailyData(payload.entries.data, rows, getLoggedUserLocale()));
      break;
    case intervals.WEEKLY:
      setData(calcWeeklyData(payload.entries.data, rows, getLoggedUserLocale()));
      break;
    case intervals.MONTHLY:
      setData(calcMonthlyData(payload.entries.data, rows, getLoggedUserLocale()));
      break;
    default:
  }
};

/* eslint-disable react/no-danger */
import {
  Alert, Box, Container, Paper, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { competitionPlanOneByClientAndSlugGet } from "../../../services/api/competition.js";
import { BgBox } from "../../shared/boxes/index.js";
import {
  DangerousHtmlDesc,
  Footer, GenericError, Header, Loading,
} from "../../shared/other/index.js";
import CompetitionSignUpButton from "../CompetitionSignUpButton/index.js";
import Events from "./_events.js";

export default ({ clientNumber, slug, footerAndHeader = true }) => {
  const theme = useTheme();
  const [plan, setPlan] = React.useState(null);
  const { t } = useTranslation();
  const {
    loading, payload, error, status, query,
  } = useQuery(competitionPlanOneByClientAndSlugGet(clientNumber, slug));
  const isXs = useMediaQuery(theme.breakpoints.down("md"));
  // const isXs = useMediaQuery("(max-width:500px)");

  React.useLayoutEffect(() => {
    if (!loading && !error && !_.isNil(payload)) {
      setPlan(payload?.data);
    }
  }, [payload]);

  if (error) {
    return <GenericError status={status} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (_.isNil(plan)) {
    return <Alert severity="warning">{t("competition_does_not_exist")}</Alert>;
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {footerAndHeader && <Header plan={plan} competition callback={query} />}
      <Box flex="1 0">
        <Container maxWidth="lg" style={{ paddingTop: 30 }}>
          <Box display="flex" flexWrap="wrap" width="100%" position="relative" marginBottom="30px">
            <BgBox bg={isXs && plan.mobileBg ? plan.mobileBg : plan.bg}>
              <Typography
                variant={plan.name.length > 15 ? "h3" : "h2"}
                style={{
                  color: "white",
                  textShadow: "0px 0px 10px black",
                  marginTop: "20px", marginLeft: "20px",
                  fontWeight: 800,
                  wordBreak: "break-word",
                }}
              >
                {plan.competitionNameHidden ? "" : plan.name}
              </Typography>

            </BgBox>
            <Box
              display="flex"
              width="100%"
              flexWrap="wrap"
              paddingTop="10px"
              position="relative"
            >
              <Box flex="1 0" padding="10px">
                <Box display="flex" flexDirection="column" justifyContent="center" width="100%" minHeight="220px" position="relative">
                  <Box position="relative">
                    <Paper style={{ display: "flex", marginBottom: "10px", minHeight: "200px" }}>
                      <Box sx={{
                        margin: "15px", wordBreak: "break-word", whiteSpace: "break-spaces", textAlign: "justify", fontSize: "large",
                      }}
                      >
                        <DangerousHtmlDesc text={plan?.competitionDesc} />
                      </Box>
                    </Paper>
                    <Box width="100%" textAlign="center">
                      <CompetitionSignUpButton plan={plan} callback={query} variant="contained" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Events plan={plan} />
        </Container>
      </Box>
      {footerAndHeader && <Footer />}
    </Box>
  );
};

import { FileCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({ handleClose, actions, form }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("create_copy")} placement="bottom" disableTouchListener>
      <IconButton onClick={() => {
        handleClose();
        actions.create({
          order: form.order,
          planId: form.planId,
          planName: form.planName,
          planInterval: form.planInterval,
          date: form.date,
          day: form.day,
          isDeadline: form.isDeadline,
          deadlineDate: form.deadlineDate,
          title: form.title,
          content: form.content,
          comment: form.comment,
          isScore: form.isScore,
          isScoreHidden: form.isScoreHidden,
          isHidden: form.isHidden,
          scoreUnit: form.scoreUnit,
          statisticId: form.statisticId,
          statisticName: form.statisticName,
          media: form.media,
          competitionCategory: form.competitionCategory,
          isTieBreak: form.isTieBreak,
          tieBreakUnit: form.tieBreakUnit,
          tieBreakDesc: form.tieBreakDesc,
          isConfirmationRequired: form.isConfirmationRequired,
          isTimeCap: form.isTimeCap,
        });
      }}
      >
        <FileCopy fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

import { SportsScore } from "@mui/icons-material";
import {
  Alert, Box, Divider, Paper, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";

import { useSizeStyle } from "../../../services/hooks/index.js";
import { MediaPreviewPanel } from "../../media/index.js";
import { UrlifiedTypography } from "../../shared/other/index.js";

export default ({ t, item }) => {
  const { select } = useSizeStyle({
    xs: { marginBottom: "10px" }, sm: { marginBottom: "10px" }, md: { marginBottom: "10px" }, lg: { marginLeft: "5px", marginRight: "5px", marginBottom: "10px" }, xl: { marginLeft: "5px", marginRight: "5px", marginBottom: "10px" },
  });

  return (
    <Box style={select()}>
      <Paper>
        <Typography variant="h4" color="default" style={{ wordBreak: "break-word" }} fontWeight={600} padding="10px">
          {item.title}
        </Typography>
        <Divider style={{ marginLeft: 0, marginRight: 0 }} />
        <Box style={{ padding: "10px" }}>
          <UrlifiedTypography style={{
            whiteSpace: "pre-line", width: "100%", marginTop: 15, marginBottom: 15, wordBreak: "break-word",
          }}
          >
            {item.content}
          </UrlifiedTypography>
          {!_.isEmpty(item.tieBreakDesc) && (
          <Alert icon={<SportsScore />} severity="info" variant="filled">
            <b>
              {t("tie_break")}
              :
              {" "}
            </b>
            {item.tieBreakDesc}
          </Alert>
          )}
          {!_.isEmpty(item.comment) && (
          <Alert severity="info" style={{ whiteSpace: "pre-line", wordBreak: "break-word", marginTop: _.isEmpty(item.tieBreakDesc) ? 0 : 10 }}>
            <UrlifiedTypography>{item.comment}</UrlifiedTypography>
          </Alert>
          )}

          {!_.isNil(item.media) && !_.isEmpty(item.media[0]) && (
          <Box flex="1 0 100%" style={{ paddingTop: 10 }}>
            <MediaPreviewPanel data={item.media} coach={false} />
          </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

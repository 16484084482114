import { Pageview } from "@mui/icons-material";
import {
  Alert, Box, Card, CardActions, CardContent, CardHeader, Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";
import { Trans, useTranslation } from "react-i18next";

import { userMeGet } from "../../../services/api/user.js";
import { plansForSaleAbsoluteUrl, profileUrl } from "../../../services/routes.js";
import {
  CustomEditButton, OpenTabButton, PreviewButton, TextCopyButton,
} from "../../shared/buttons/index.js";
import { GenericError, Loading } from "../../shared/other/index.js";
import GenerateLink from "./_generate-link.js";
import StripeAccountButton from "./_stripe-account-button.js";
import StripeStatusChips from "./_stripe-status-chips.js";
import UserShopPreviewDialog from "./UserShopPreviewDialog/index.js";
import UserUniqueSlugFormDialog from "./UserUniqueSlugFormDialog/index.js";

export default React.memo(() => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [pfsUrl, setPfsUrl] = useState(null);
  const {
    loading, error, payload, status, query,
  } = useQuery(userMeGet());

  useEffect(() => {
    if (!loading && payload) {
      setUser(payload.data);
      setPfsUrl(plansForSaleAbsoluteUrl(payload.data.uniqueSlug || payload.data.clientNumber));
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError status={status} />;
  }

  if (loading) {
    return <Box position="relative" width="100%"><Loading /></Box>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" flexWrap="wrap" flex="1 0 100%">
        <Box flex="1 0">
          <StripeAccountButton />
        </Box>
        <Box flex="1 0" display="flex" justifyContent="flex-end" paddingLeft="10px" flexWrap="wrap" position="relative">
          <StripeStatusChips />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" flex="1 0 100%" marginTop="20px">
        <Card variant="elevation" sx={{ width: "100%" }}>
          <CardHeader
            title={(
              <Typography variant="h3" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
                {t("your_page")}
              </Typography>
            )}
          >
            <Box />
          </CardHeader>
          <CardContent>
            <Typography variant="h4" align="center" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>{pfsUrl}</Typography>
            <Alert
              severity="info"
              sx={{
                marginTop: "20px", whiteSpace: "pre-line", width: "100%", wordBreak: "break-word",
              }}
            >
              <Trans i18nKey="my_page_editing_info">
                ...
                <Link href={profileUrl()}>{t("profile")}</Link>
                ...
              </Trans>
            </Alert>
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <OpenTabButton link={pfsUrl} size="medium" />
              <TextCopyButton textToCopy={pfsUrl} label="copy_link" size="medium" />
              <PreviewButton
                args={[user.clientNumber]}
                placement="top"
                buttonSize="medium"
                iconSize="small"
                icon={Pageview}
                previewDialog={UserShopPreviewDialog}
              />
              <CustomEditButton DialogComponent={UserUniqueSlugFormDialog} callback={query} tooltip="change_name" color="default" />
            </Box>
          </CardActions>
        </Card>
      </Box>

      <Box display="flex" flexWrap="wrap" flex="1 0 100%" marginTop="20px" />
      <GenerateLink />
    </Box>
  );
});

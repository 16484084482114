import {
  Box, Divider, Paper, Typography, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { scoreDelete, scoreMyGet } from "../../../../../services/api/score.js";
import { intervals, models } from "../../../../../services/enums/index.js";
import { useCommonDateFormats } from "../../../../../services/hooks/index.js";
import { LockButton, ModelActionButton, OpenTabButton } from "../../../../shared/buttons/index.js";
import { GenericError, Loading, ValueView } from "../../../../shared/other/index.js";
import valueViewPlain from "./_value-view-plain.js";

export default React.memo(({
  actions, entry, plan, title = "score", isTieBreak = false,
}) => {
  const { t } = useTranslation();
  const [score, setScore] = React.useState(null);
  const [prevScore, setPreScore] = React.useState(null);
  const {
    loading, payload, query, error, status,
  } = useQuery(scoreMyGet(entry._id, isTieBreak));
  const { formatWholeDate, formatDate } = useCommonDateFormats();
  const theme = useTheme();

  const scoreUnit = isTieBreak ? entry.tieBreakUnit : entry.scoreUnit;

  React.useEffect(() => {
    if (!loading && !_.isNil(payload)) {
      setScore(payload.score);
      setPreScore(payload.prevScore);
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError status={status} />;
  }

  const showScoreDeadlineText = () => plan.interval === intervals.COMPETITION && entry.isDeadline && !entry.isDeadlineReached;
  const showScoreDeadlineReachedText = () => plan.interval === intervals.COMPETITION && entry.isDeadline && entry.isDeadlineReached;
  const showWaitForAdminToAddScore = () => plan.interval === intervals.COMPETITION && !entry.isDeadline && _.isNil(score);
  const showAddScoreButton = () => {
    if (!_.isNil(score)) {
      return false;
    }

    if (plan.interval === intervals.COMPETITION) {
      // if isDeadline is true, then button should be diplayed but only when deadline hasn't been reached
      return entry.isDeadline && !entry.isDeadlineReached;
    }
    // for all other cases, if there is no score, button will be displayed
    return true;
  };
  const showEditingButtons = () => {
    if (_.isNil(score)) {
      return false;
    }

    if (plan.interval === intervals.COMPETITION) {
      // if isDeadline is true, then button should be diplayed, but only when deadline date has not been reached
      return entry.isDeadline && !entry.isDeadlineReached;
    }
    // for all other cases, if there is score added, buttons will be displayed
    return true;
  };

  const reload = () => {
    query();
    actions.reload();
  };

  return (
    <Paper style={{
      marginBottom: "20px", paddingTop: 8, position: "relative", flexGrow: 1,
    }}
    >
      {loading && <Loading />}
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Typography variant="h3" style={{ fontWeight: 900, paddingTop: 8 }}>{t(title)}</Typography>
        <Divider variant="middle" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />
        {isTieBreak && (
        <Box flex="1 0 100%">
          <Box textAlign="center">
            <Typography variant="h5">{entry.tieBreakDesc}</Typography>
          </Box>
        </Box>
        )}
        {showScoreDeadlineText() && (
        <Box flex="1 0 100%">
          <Box textAlign="center">
            <Typography variant="caption">
              {t("score_deadline")}
              :
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="caption">{formatWholeDate(entry.deadlineDate)}</Typography>
          </Box>
        </Box>
        )}
        {showScoreDeadlineReachedText() && (
        <Box flex="1 0 100%">
          <Box textAlign="center">
            <Typography variant="caption" color={theme.palette.error.main}>
              {t("score_deadline_reached")}
              .
            </Typography>
          </Box>
        </Box>
        )}
        {showWaitForAdminToAddScore() && (
        <Box flex="1 0 100%">
          <Box textAlign="center">
            <Typography variant="caption">{t("wait_for_admin_to_add_score")}</Typography>
          </Box>
        </Box>
        )}
        {!_.isNil(score) && <ValueView unit={scoreUnit} data={score} />}
        {!_.isNil(score) && (
        <Box flex="1 0 100%">
          <Box textAlign="center">
            <Typography variant="caption">
              {t("added")}
              :
              {" "}
              {formatDate(_.isNil(score.updatedAt) ? score.createdAt : score.updatedAt)}
            </Typography>
          </Box>
        </Box>
        )}
        {showAddScoreButton() && (
        <Box style={{ marginBottom: 10 }}>
          <ModelActionButton
            type="add"
            model={models.SCORE}
            variant="fabh1"
            tooltip="add_score"
            callback={reload}
            initData={{ entryId: entry._id, scoreUnit, isTieBreak }}
            options={{
              planInterval: plan.interval, isDeadline: entry.isDeadline, isConfirmationRequired: entry.isConfirmationRequired, isTimeCap: entry.isTimeCap,
            }}
          />
        </Box>
        )}
        {showEditingButtons() && (
        <Box width="100%" display="flex" flexWrap="wrap" justifyContent="center">
          <Box display="flex" flexWrap="wrap">
            <ModelActionButton
              type="edit"
              modelId={score._id}
              fontSize="inherit"
              variant="iconbutton"
              model={models.SCORE}
              tooltip="edit"
              callback={reload}
              options={{
                planInterval: plan.interval, isDeadline: entry.isDeadline, isConfirmationRequired: entry.isConfirmationRequired, isTieBreak, isTimeCap: entry.isTimeCap,
              }}
            />
            {!_.isNil(score.videoLink) && !_.isEmpty(score.videoLink) && <OpenTabButton link={score.videoLink} tooltipTitle="video_link" placement="bottom" size="medium" />}
            <ModelActionButton
              remove={scoreDelete}
              modelId={score._id}
              fontSize="inherit"
              callback={reload}
            />
            {plan.interval !== intervals.COMPETITION && <LockButton enabled={score.public} titleEnabled="score_is_public" titleDisabled="score_is_private" disabled />}
          </Box>
        </Box>
        )}

        {!_.isNil(prevScore) && <Divider variant="middle" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />}
        {!_.isNil(prevScore) && (
        <Typography variant="caption" style={{ marginBottom: 5 }}>
          {t("previously")}
          :
          {" "}
          {valueViewPlain(prevScore.scoreUnit, prevScore)}
        </Typography>
        )}
      </Box>
    </Paper>
  );
});

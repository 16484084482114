import _ from "lodash";

import { localStorageDict } from "../../services/enums/index.js";
import { writeStorage } from "../../services/utils/index.js";

export default currentTheme => {
  if (_.isNil(currentTheme)) {
    const isDark = window.matchMedia("(prefers-color-scheme: dark)");

    writeStorage(
      localStorageDict.THEME.NAME,
      isDark.matches
        ? localStorageDict.THEME.VALUES.DARK
        : localStorageDict.THEME.VALUES.LIGHT,
    );

    return isDark.matches
      ? localStorageDict.THEME.VALUES.DARK
      : localStorageDict.THEME.VALUES.LIGHT;
  }

  return currentTheme;
};

import {
  Box, Divider, Typography, useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";

import { AvatarCircle } from "../../shared/other/index.js";

export default ({ item, highlight = [] }) => {
  const theme = useTheme();
  const [lines, setLines] = useState(2);

  return (
    <Box display="flex">
      <Box marginRight={1}>
        <AvatarCircle user={item.user} />
      </Box>
      <Box flex="1" display="flex" flexWrap="wrap">
        <Box flex="1 0 100%">
          <Typography variant="body1" style={{ width: "100%", fontWeight: 600 }}>
            <Highlighter
              highlightStyle={{ backgroundColor: theme.palette.action.selected }}
              searchWords={highlight}
              autoEscape
              textToHighlight={item.user.name ?? "..."}
            />
          </Typography>
        </Box>
        <Box flex="1 0 100%" display="flex" flexWrap="wrap">
          <Box display="flex" flexDirection="column" alignSelf="flex-end" marginRight={2}>
            <Typography variant="caption" style={{ fontSize: 10 }}>
              <Highlighter
                highlightStyle={{ backgroundColor: theme.palette.action.selected }}
                searchWords={highlight}
                autoEscape
                textToHighlight={item.user.email}
              />
            </Typography>
          </Box>
        </Box>
        {!_.isNil(item.note) && (
          <Box flex="1 0 100%" display="flex" flexWrap="wrap">
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <Box display="flex" flexDirection="column" alignSelf="flex-end" marginRight={2}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: 10,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: lines,
                  cursor: lines === 0 ? "inherit" : "pointer",
                }}
                onClick={() => setLines(0)}
              >
                <Highlighter
                  highlightStyle={{ backgroundColor: theme.palette.action.selected }}
                  searchWords={highlight}
                  autoEscape
                  textToHighlight={item.note}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

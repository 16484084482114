import React from "react";

import { userMeGet, userMePut } from "../../../../services/api/user.js";
import { useFormEngine } from "../../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../../services/utils/index.js";
import { TextField } from "../../../shared/fields/index.js";
import { FormDialogTemplate } from "../../../shared/templates/index.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

export default React.memo(({ actions, callback }) => {
  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, null, userMePut, userMeGet, {
    uniqueSlug: "",
  }, formSchema(), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="unique_name_in_url"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <TextField
          label="name"
          fieldName="uniqueSlug"
          form={form}
          isFieldError={isFieldError}
          fieldErrorMessage={fieldErrorMessage}
          setFieldValue={setFieldValue}
        />
      )}
    />
  );
});

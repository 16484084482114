import { methods } from "../enums/index.js";
import { apiAction } from "./index.js";

export const noteDataPost = data => ["/note", methods.POST, data];
export const noteDataPut = data => [`/note/${data._id}`, methods.PUT, data];
export const noteDelete = id => [`/note/${id}`, methods.DELETE];
export const noteOneGet = id => apiAction(`/note/one/${id}`);
export const noteOneByEntryGet = entryId => apiAction(`/note/one-by-entry/${entryId}`);
export const noteFilterByEntryGet = (search = "", page = 1, limit = 100, sort = "-createdAt", filter = "", entryId = "") => apiAction(
  `/note/filter-by-entry/${entryId}?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const noteFilterByEntryPublicGet = (entryId = "", search = "", page = 1, limit = 100, sort = "-createdAt", filter = "") => apiAction(
  `/note/filter-by-entry-public/${entryId}?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const noteFilterGet = (search = "", page = 1, limit = 10, sort = "-createdAt", filter = "") => apiAction(
  `/note/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);

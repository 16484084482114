import _ from "lodash";

import { createFuncDef } from "../../../services/utils/index.js";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      content: data.content,
      entryId: data.entryId,
      coachCanSee: data.coachCanSee,
      isPublic: _.isNil(data.isPublic) ? false : data.isPublic,
    });
  }),
];

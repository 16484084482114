import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { planFilterGet } from "../../../services/api/plan.js";
import { planShareCoachHasGet, planShareFilterCoachGet } from "../../../services/api/plan-share.js";
import { models } from "../../../services/enums/index.js";
import { PlanSharePanelItem } from "../../plan-share/index.js";
import { DataPanelTemplate } from "../../shared/templates/index.js";
import { PlansPanelItem } from "../index.js";

export default React.memo(({ use, bgFrom, options = { showCompetitions: true, onlyWithPrice: false } }) => {
  const { t } = useTranslation();
  const [alignment, setAlignment] = React.useState("my");
  const handleChange = event => setAlignment(event.currentTarget.value);
  const { loading, error } = useQuery(planShareCoachHasGet());

  return (
    <>
      {!loading && !error && (
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="plan_toggle"
        style={{ marginBottom: "5px" }}
      >
        <ToggleButton value="my">{t("my_plans")}</ToggleButton>
        <ToggleButton value="shared">{t("shared_plans")}</ToggleButton>
      </ToggleButtonGroup>
      )}
      {alignment === "my" && (
      <DataPanelTemplate
        use={use}
        filterGet={planFilterGet}
        dataPrefix="plans"
        addTooltip="add_plan"
        sortDescs={[
          { label: t("sort_by_order"), desc: "-order" },
          { label: t("from_newest"), desc: "-createdAt" },
          { label: t("from_oldest"), desc: "createdAt" },
        ]}
        filterDescs={{
          hidden: { label: t("only_hidden"), type: "boolean", value: false },
          showCompetitions: { type: "hidden", value: options.showCompetitions },
          onlyWithPrice: { type: "hidden", value: options.onlyWithPrice },
        }}
        model={models.PLAN}
        panelItem={PlansPanelItem}
        bgFrom={bgFrom}
      />
      )}
      {alignment === "shared" && (
      <DataPanelTemplate
        use={use}
        filterGet={planShareFilterCoachGet}
        dataPrefix="planShares"
        disableAdding
        sortDescs={[
          { label: t("from_newest"), desc: "-createdAt" },
          { label: t("from_oldest"), desc: "createdAt" },
        ]}
        filterDescs={{
          hidden: { label: t("only_hidden"), type: "boolean", value: false },
          usePlanId: { type: "boolean", value: true, hidden: true },
        }}
        model={models.PLAN_SHARE}
        panelItem={PlanSharePanelItem}
        bgFrom={bgFrom}
      />
      )}
    </>
  );
});

import {
  Equalizer, Note, Percent, ViewModule,
} from "@mui/icons-material";
import {
  Box, IconButton, Paper, Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import NotesPreviewDialog from "../NotesPreviewDialog/index.js";
import PlansVisibilityDialog from "../PlansVisibilityDialog/index.js";
import ScoresPreviewDialog from "../ScoresPreviewDialog/index.js";
import WeightCalculatorDialog from "./WeightCalculatorDialog/index.js";

export default memo(({ reloadPlans, plans = [] }) => {
  const { t } = useTranslation();

  const actions = {
    plans: {},
    scores: {},
    notes: {},
    weights: {},
  };

  return (
    <Paper style={{ display: "flex", marginBottom: "30px", width: "100%" }}>
      <Box flex="1" display="flex" margin="10px">

        <Tooltip placement="bottom" title={t("plans")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.plans.open()}>
            <ViewModule />
          </IconButton>
        </Tooltip>
        <PlansVisibilityDialog actions={actions.plans} plans={plans} reloadPlans={reloadPlans} />

        <Tooltip placement="bottom" title={t("scores")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.scores.open()}>
            <Equalizer />
          </IconButton>
        </Tooltip>
        <ScoresPreviewDialog actions={actions.scores} />

        <Tooltip placement="bottom" title={t("notes")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.notes.open()}>
            <Note />
          </IconButton>
        </Tooltip>
        <NotesPreviewDialog actions={actions.notes} />

        <Tooltip placement="bottom" title={t("weight_calculator")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.weights.open()}>
            <Percent />
          </IconButton>
        </Tooltip>
        <WeightCalculatorDialog actions={actions.weights} />

      </Box>
    </Paper>
  );
});

/* eslint-disable camelcase */
import { createFuncDef, getDefault } from "../../../../services/utils/index.js";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      link__instagram: getDefault(data.link, "instagram", ""),
      link__facebook: getDefault(data.link, "facebook", ""),
      link__twitter: getDefault(data.link, "twitter", ""),
      link__website: getDefault(data.link, "website", ""),
    });
  }),
];

import {
  IconButton, Menu, MenuItem, Tooltip, Typography,
} from "@mui/material";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../../services/api/index.js";
import { userUpdateLanguagePut } from "../../../../services/api/user.js";
import { localStorageDict } from "../../../../services/enums/index.js";
import { useAnchorControl, useAuthenticated, useLocalStorage } from "../../../../services/hooks/index.js";
import { getLocale, safeUpperCase, writeStorage } from "../../../../services/utils/index.js";

export default () => {
  const { t, i18n } = useTranslation();
  const [lang] = useLocalStorage(localStorageDict.LANGUAGE, getLocale().language);
  const { anchorEl, setAnchor, removeAnchor } = useAnchorControl();
  const { mutate } = useMutation(apiAction);
  const { isAuthenticated } = useAuthenticated();

  const changeLang = language => {
    if (isAuthenticated()) {
      mutate(...userUpdateLanguagePut({ language }));
    }
    removeAnchor();
    writeStorage(localStorageDict.LANGUAGE, language);
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Tooltip title={t("change_language")} placement="bottom" disableTouchListener>
        <IconButton size="large" aria-controls="switch-language" aria-haspopup="true" onClick={setAnchor} style={{ width: "48px", height: "48px" }}>
          <Typography variant="button">{safeUpperCase(lang)}</Typography>
        </IconButton>
      </Tooltip>
      <Menu
        id="header-menu-coach"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={removeAnchor}
      >
        <MenuItem onClick={() => changeLang("pl")}>PL</MenuItem>
        <MenuItem onClick={() => changeLang("en")}>EN</MenuItem>
      </Menu>
    </>
  );
};

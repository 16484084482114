import { Add, Close } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../../../services/api/index.js";
import { userMePut, userMeWeightsGet } from "../../../../../services/api/user.js";
import { useDebounceState } from "../../../../../services/hooks/index.js";
import { createFuncDef } from "../../../../../services/utils/index.js";
import { NumberField } from "../../../../shared/fields/index.js";
import { Loading } from "../../../../shared/other/index.js";
import { PreviewDialogTemplate } from "../../../../shared/templates/index.js";

export default React.memo(({ actions }) => {
  const { t } = useTranslation();
  const [weights, setWeights] = useState(null);
  const [percent, setPercent] = useState({ percent: 100 });
  const [search, setSearch] = useState("");
  const [newExercise, setNewExercise] = useState("");

  const {
    loading, error, payload, query,
  } = useQuery(userMeWeightsGet(), false);
  const { mutate } = useMutation(apiAction);
  const { setDebounce } = useDebounceState(() => {
    if (_.isNil(weights)) {
      return;
    }

    mutate(...userMePut({ weights: JSON.stringify(weights) }));
    // big value to never reach initial func call (it causes 1RM reset)
  }, 31536000000);

  useEffect(() => {
    if (!loading && !error) {
      if (_.isNil(payload?.data?.weights)) {
        setWeights([
          { key: "back_squat", back_squat: 0, name: "Back Squat" },
          { key: "bench_press", bench_press: 0, name: "Bench Press" },
          { key: "clean_and_jerk", clean_and_jerk: 0, name: "Clean and Jerk" },
          { key: "clean", clean: 0, name: "Clean" },
          { key: "cluster", cluster: 0, name: "Cluster" },
          { key: "deadlift", deadlift: 0, name: "Deadlift" },
          { key: "front_squat", front_squat: 0, name: "Front Squat" },
          { key: "hang_clean", hang_clean: 0, name: "Hang Clean" },
          { key: "hang_power_clean", hang_power_clean: 0, name: "Hang Power Clean" },
          { key: "hang_power_snatch", hang_power_snatch: 0, name: "Hang Power Snatch" },
          { key: "hang_snatch", hang_snatch: 0, name: "Hang Snatch" },
          { key: "incline_bench_press", incline_bench_press: 0, name: "Incline Bench Press" },
          { key: "military_press", military_press: 0, name: "Military Press" },
          { key: "overhead_squat", overhead_squat: 0, name: "Overhead Squat" },
          { key: "power_clean", power_clean: 0, name: "Power Clean" },
          { key: "power_snatch", power_snatch: 0, name: "Power Snatch" },
          { key: "push_jerk", push_jerk: 0, name: "Push Jerk" },
          { key: "push_press", push_press: 0, name: "Push Press" },
          { key: "snatch", snatch: 0, name: "Snatch" },
          { key: "sumo_deadlift", sumo_deadlift: 0, name: "Sumo Deadlift" },
          { key: "thruster", thruster: 0, name: "Thruster" },
        ]);
      } else {
        setWeights(JSON.parse(payload?.data?.weights));
      }
    }
  }, [loading, payload]);

  const setExerciseWeight = (key, value) => {
    if (loading || error) {
      return;
    }
    const weightsClone = _.cloneDeep(weights);
    _.map(weightsClone, item => {
      if (item.key === key) {
        item[key] = value;
      }
    });
    setWeights(weightsClone);
    setDebounce(JSON.stringify(weights), 2000);
  };

  const addExercise = () => {
    if (loading || error) {
      return;
    }
    const newOneKey = _.snakeCase(newExercise);

    if (newExercise.length === 0 || newOneKey.length === 0) {
      return;
    }
    const weightsClone = _.cloneDeep(weights);

    if (_.filter(weightsClone, w => w.key === newOneKey).length > 0) {
      return;
    }
    const newOne = {
      key: _.snakeCase(newExercise), [newOneKey]: 0, name: newExercise, isCustom: true,
    };
    weightsClone.push(newOne);
    setWeights(weightsClone);
    setNewExercise("");
    setDebounce(JSON.stringify(weights), 2000);
  };

  const removeExercise = key => {
    if (loading || error) {
      return;
    }
    const weightsClone = _.cloneDeep(weights);
    setWeights(_.filter(weightsClone, w => w.key !== key));
    setDebounce(JSON.stringify(weights), 2000);
  };

  return (
    <PreviewDialogTemplate
      maxWidth="sm"
      actions={actions}
      title="weight_calculator"
      isPaper
      onOpenCallbacks={[createFuncDef(query)]}
      titleButtons={(
        <NumberField
          label="percent"
          isFieldError={() => {}}
          fieldErrorMessage={() => {}}
          fieldName="percent"
          form={percent}
          setFieldValue={(fieldName, value) => setPercent({ percent: value })}
          size="small"
          min="0"
          max="100"
          sx={{ width: "80px" }}
        />
      )}
      content={!_.isNil(weights) && !loading && !error && (
        <TableContainer component={Paper}>
          {loading && <Loading />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="head"><TextField value={search} label={t("search")} variant="standard" size="small" onChange={e => setSearch(e.target.value)} sx={{ }} /></TableCell>
                <TableCell variant="head"><Typography fontWeight={800}>{t("your_1rm")}</Typography></TableCell>
                <TableCell variant="head"><Typography fontWeight={800}>{t("weight")}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weights.map(w => {
                if (search.length > 0 && w.name.toLowerCase().indexOf(search.toLowerCase(), 0) === -1) {
                  return null;
                }

                return (
                  <TableRow key={w.key}>
                    <TableCell variant="body" style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
                      <p style={{ display: "inline" }}>
                        {w.isCustom && <IconButton color="error" size="small" onClick={() => removeExercise(w.key)} style={{ left: "-15px", marginRight: "-15px" }}><Close /></IconButton>}
                        {w.name}
                      </p>
                    </TableCell>
                    <TableCell variant="body">
                      <NumberField
                        label=""
                        isFieldError={() => {}}
                        fieldErrorMessage={() => {}}
                        fieldName={w.key}
                        form={w}
                        setFieldValue={(fieldName, value) => setExerciseWeight(fieldName, value)}
                        size="small"
                        min="0"
                        max="1000"
                        sx={{ width: "80px" }}
                      />
                    </TableCell>
                    <TableCell variant="body"><Typography fontWeight={800} textAlign="center">{(w[w.key] * percent.percent) / 100}</Typography></TableCell>
                  </TableRow>
                );
              },
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  {t("add_exercise")}
                </TableCell>
                <TableCell>
                  <TextField value={newExercise} size="small" onChange={e => setNewExercise(e.target.value)} />
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <IconButton color="primary" onClick={() => addExercise()}><Add /></IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    />
  );
});

import { Lock } from "@mui/icons-material";
import { Box, Button, InputAdornment } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { TextField } from "../../components/shared/fields/index.js";
import { ErrorMessages, Loading } from "../../components/shared/other/index.js";
import { newPasswordAction } from "../../services/api/other.js";
import { useFormEngine } from "../../services/hooks/index.js";
import passwordsMatch from "../Register/_passwords-match.js";

export default React.memo(({ passwordHash }) => {
  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    password: yup.string().trim()
      .required(t("field_is_required"))
      .max(1000, "text_too_long"),
    repeatPassword: yup.string().trim()
      .required(t("field_is_required"))
      .when("password", passwordsMatch(t))
      .max(1000, "text_too_long"),
    passwordHash: yup.string().trim()
      .required(t("field_is_required"))
      .max(1000, "text_too_long"),
  });

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isFieldError, fieldErrorMessage,
  } = useFormEngine({}, newPasswordAction, null, null, {
    password: "",
    repeatPassword: "",
    passwordHash,
  }, formSchema);

  return (
    <Box width="100%">
      {isLoading() && <Loading />}
      <form onSubmit={e => e.preventDefault()} noValidate>
        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Box flex="1">
            <TextField
              inputProps={{ "data-testid": "password" }}
              variant="standard"
              type="password"
              label="password"
              fieldName="password"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              inputProps={{ "data-testid": "repeat-password" }}
              variant="standard"
              type="password"
              label="repeat_password"
              fieldName="repeatPassword"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <ErrorMessages isError={isError()} errors={errors()} />

          <Box flex="1">
            <Button type="submit" size="small" variant="contained" color="primary" style={{ width: "100%" }} onClick={handleSubmit}>
              {t("send")}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
});

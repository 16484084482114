import {
  Avatar, Box, Divider, ListItem, ListItemText,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { fileBlobGet } from "../../../services/api/file.js";
import { assetUrl } from "../../../services/routes.js";
import { dateToShortText } from "../../../services/utils/index.js";
import getUserName from "./_get-user-name.js";

export default memo(({ children, notif, title }) => {
  const { t } = useTranslation();

  const getAvatar = item => {
    if (_.isNil(item.sender)) {
      return assetUrl("mobile.png");
    }

    return _.isNil(item.sender.avatar) ? "" : fileBlobGet(item.sender.avatar);
  };

  return (
    <>
      <ListItem alignItems="flex-start" sx={{ paddingBottom: 0 }}>
        <ListItemText
          sx={{
            marginBottom: 0, whiteSpace: "pre-line", width: "100%", wordBreak: "break-word",
          }}
          disableTypography
          primary={(
            <Box display="flex" alignItems="center" marginBottom="10px">
              <Avatar
                sx={{
                  width: "24px", height: "24px", fontSize: "0.75rem", marginRight: "10px",
                }}
                alt={getUserName(notif)}
                src={getAvatar(notif)}
                title={getUserName(notif)}
              >
                {getUserName(notif).substr(0, 1)}
              </Avatar>
              <Typography variant="body1" fontWeight={800} sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>{title}</Typography>
            </Box>
          )}
          secondary={(
            <Box display="flex" flexDirection="column" sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
              { children }
              <Typography variant="caption" fontSize="small" color="grey" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
                {getUserName(notif)}
                {" "}
                —
                {" "}
                {dateToShortText(notif.createdAt, t)}
              </Typography>
            </Box>
            )}
        />
      </ListItem>
      <Divider variant="fullWidth" />
    </>
  );
});

import {
  Badge, Box, ListItem, ListItemAvatar, ListItemText, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { createUserDisplayName } from "../../../services/utils/index.js";
import { AvatarCircle } from "../../shared/other/index.js";
import scorePlainText from "./_score-plain-text.js";

export default ({ competitor, isEvent }) => {
  const { t } = useTranslation();
  return (
    <ListItem alignItems="flex-start" disabled={!_.isNil(competitor.noScoreYet) && competitor.noScoreYet}>
      <ListItemAvatar>
        <Badge
          variant="standard"
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={competitor.place}
        >
          <AvatarCircle user={competitor} />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={(
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 600 }} color="inherit">
                {createUserDisplayName(competitor)}
              </Typography>
              {competitor?.card?.club && (
                <Typography variant="caption" style={{ fontWeight: 600 }} color="inherit">
                  {competitor?.card?.club}
                </Typography>
              )}
            </Box>
          </Box>
              )}
        secondary={(
          <Typography component="span" variant="body2" color="inherit">
            {!isEvent && (
            <>
              {t("points")}
              :
              {" "}
              {competitor.points}
            </>
            )}
            {isEvent && (
            <>
              {t("score")}
              :
              {" "}
              {scorePlainText(competitor.score?.scoreUnit, competitor.score)}
            </>
            )}
            {isEvent && competitor.score?.tieBreak && (
            <>
&nbsp;/&nbsp;
              {t("tie_break")}
              :
              {scorePlainText(competitor.score?.tieBreak?.scoreUnit, competitor.score?.tieBreak)}
            </>
            )}
          </Typography>
              )}
      />
    </ListItem>
  );
};

import { methods } from "../enums/index.js";
import { apiAction } from "./index.js";

export const planShareDataPost = data => ["/plan-share", methods.POST, data];
export const planShareDataPut = data => [`/plan-share/${data._id}`, methods.PUT, data];
export const planShareDelete = id => [`/plan-share/${id}`, methods.DELETE];
export const planShareOneGet = id => apiAction(`/plan-share/one/${id}`);
export const planShareFilterGet = (search = "", page = 1, limit = 10, sort = "-createdAt", filter = "") => apiAction(
  `/plan-share/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const planShareFilterCoachGet = (search = "", page = 1, limit = 10, sort = "-createdAt", filter = "") => apiAction(
  `/plan-share/filter-coach?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const planShareCoachHasGet = () => apiAction("/plan-share/coach-has");

import _ from "lodash";

import { intervals } from "../../../../services/enums/index.js";

export default (setFieldValues, fieldName, fieldId) => item => {
  setFieldValues({
    [fieldId]: item._id,
    [fieldName]: item.name,
    planInterval: item.interval,
    ...(_.isEqual(item.interval, intervals.COMPETITION) && { isScore: true }),
  });
};

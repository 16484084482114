/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Button } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { fileBlobGet } from "../../../services/api/file.js";
import { imageOrVideo } from "../../../services/utils/index.js";
import { MyPdfViewer } from "../../shared/other/index.js";
import { PreviewDialogTemplate } from "../../shared/templates/index.js";

export default React.memo(({ actions = {} }) => {
  const { t } = useTranslation();
  const dialogActions = {};
  const [name, setName] = React.useState(null);
  const [file, setFile] = React.useState(null);

  actions.open = item => {
    setName(item.name);
    setFile(item.file);
    dialogActions.open();
  };

  return (
    <PreviewDialogTemplate
      actions={dialogActions}
      title={name}
      content={(
        <>
          {!_.isNil(file) && file.ext === "pdf" && <MyPdfViewer file={fileBlobGet(file)} />}
          {!_.isNil(file) && file.ext !== "pdf" && (
          <Box flex="1 0 100%" textAlign="center">
            {imageOrVideo(file.mime) === 1 && (
            <img
              src={fileBlobGet(file)}
              style={{ maxWidth: "100%" }}
              alt={name}
            />
            )}
            {imageOrVideo(file.mime) === 0 && (
            <video width="100%" controls preload="metadata">
              <source src={fileBlobGet(file)} type={file.mime} />
              VIDEO
            </video>
            )}
          </Box>
          )}
        </>
          )}
      buttons={!_.isNil(file) && (
      <Button
        href={fileBlobGet(file)}
        color="primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("show_on_full_screen")}
      </Button>
      )}
    />
  );
});

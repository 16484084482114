import { PhotoCamera, PictureAsPdf } from "@mui/icons-material";
import {
  Box, FormHelperText, FormLabel, IconButton, Input, Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

import { marginBottomForFields } from "../../../../services/utils/index.js";
import handleCapture from "./_handle-capture.js";

export default ({
  setFieldValue, setFieldValues, label, accept, iconCamera = true, iconPdf = false, helperText = null, type = "image",
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = React.useState(null);

  return (
    <Box flex="1 0 100%" style={{ marginBottom: marginBottomForFields() }}>
      {t(label)}
      <Input
        type="file"
        accept={accept}
        style={{ display: "none" }}
        id="fileSelectField"
        onChange={handleCapture(t, enqueueSnackbar, setFieldValue, setFieldValues, setName, type)}
      />
      <FormLabel htmlFor="fileSelectField">
        <Tooltip title={t("select_file")} placement="bottom" disableTouchListener>
          <IconButton color="primary" component="span">
            {iconCamera && !iconPdf && <PhotoCamera />}
            {iconPdf && !iconCamera && <PictureAsPdf />}
          </IconButton>
        </Tooltip>
        {helperText && !name && <FormHelperText>{helperText}</FormHelperText>}
        {name && <FormHelperText>{`${t("file_you_selected_is")}: ${name}`}</FormHelperText>}
      </FormLabel>
    </Box>
  );
};

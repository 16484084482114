import { UploadFile } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectDialogTemplate } from "../../shared/templates/index.js";
import { EntriesPanel } from "../index.js";
import useEntries from "./_use-entries.js";

export default ({ setFieldValues }) => {
  const actions = {};
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("use_previous_entries")} placement="bottom" disableTouchListener>
        <IconButton onClick={() => actions.open()}>
          <UploadFile fontSize="small" />
        </IconButton>
      </Tooltip>
      <SelectDialogTemplate
        title="select_entry"
        use={useEntries(setFieldValues)}
        modelPanel={EntriesPanel}
        actions={actions}
      />
    </>
  );
};

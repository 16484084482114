import React from "react";
import { useTranslation } from "react-i18next";

import { userChangeEmailPut } from "../../../../services/api/user.js";
import { useFormEngine } from "../../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../../services/utils/index.js";
import { TextField } from "../../../shared/fields/index.js";
import { FormDialogTemplate } from "../../../shared/templates/index.js";
import formSchema from "./_form-schema.js";

const UserProfileEmailChangeFormDialog = React.memo(({ actions, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, null, userChangeEmailPut, null, {
    newEmail: "",
    repeatNewEmail: "",
  }, formSchema(t), onSuccessCallbacks(callback));

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="email_change"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            label="new_email"
            fieldName="newEmail"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="repeat_new_email"
            fieldName="repeatNewEmail"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    />
  );
});

export default UserProfileEmailChangeFormDialog;

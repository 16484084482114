import {
  Button, Dialog, DialogActions, DialogTitle, ThemeProvider, useMediaQuery,
} from "@mui/material";
import React from "react";
import { confirmable } from "react-confirm";

export default confirmable(props => {
  const fullScreen = useMediaQuery(props.theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={props.theme}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        open={props.show}
        onClose={() => props.proceed(false)}
      >
        <DialogTitle style={{ wordBreak: "break-word" }}>{props.t(props.title)}</DialogTitle>
        <DialogActions>
          <Button onClick={() => props.proceed(false)} color="secondary" aria-label="cancel">{props.t("cancel")}</Button>
          <Button onClick={() => props.proceed(true)} color="primary" variant="contained" aria-label="confirm">{props.t("confirm")}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});

import { FormControl, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGenericInputStyle } from "../../../../services/hooks/index.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, fieldName, label,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <DateTimePicker
        autoOk
        name={fieldName}
        label={t(label)}
        inputFormat="dd.MM.yyyy HH:mm"
        mask="__.__.____ __:__"
        value={form[fieldName]}
        onChange={val => setFieldValue(fieldName, val)}
        renderInput={args => (
          <TextField
            {...args}
            error={isFieldError(fieldName)}
            helperText={fieldErrorMessage(fieldName)}
          />
        )}
      />
    </FormControl>
  );
};

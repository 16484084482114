import { PersonAdd } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { CustomersPanel } from "../../customer/index.js";
import { SelectDialogTemplate } from "../../shared/templates/index.js";
import useCustomer from "./_use-customer.js";

export default ({ setFieldValue }) => {
  const actions = {};
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("select_from_customers")} placement="bottom" disableTouchListener>
        <IconButton onClick={() => actions.open()}>
          <PersonAdd fontSize="small" />
        </IconButton>
      </Tooltip>
      <SelectDialogTemplate
        title="select_customer"
        use={useCustomer(setFieldValue)}
        modelPanel={CustomersPanel}
        actions={actions}
        options={{ isCoach: true }}
      />
    </>
  );
};

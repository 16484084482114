import React from "react";

import { ScoresPanel } from "../../../score/index.js";
import { PreviewDialogTemplate } from "../../../shared/templates/index.js";

export default React.memo(({ actions = {} }) => (
  <PreviewDialogTemplate
    actions={actions}
    title="scores"
    maxWidth="xs"
    breakpointPixels={450}
    content={<ScoresPanel bgFrom="MuiDialog" overrideSm />}
  />
));

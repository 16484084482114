import { createFuncDef } from "../../../services/utils/index.js";

export default setFile => setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      data: "none",
      name: data.name,
      description: data.description,
      fileId: data.fileId,
    });
    setFile(data.file);
  }),
];

import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";

import { localStorageDict } from "../../../services/enums/index.js";
import { useLocalStorage } from "../../../services/hooks/index.js";

export default ({ body, provided, snapshot }) => {
  const th = useTheme();
  const [theme] = useLocalStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);

  const selectDraggingBg = () => (_.isEqual(theme, localStorageDict.THEME.VALUES.DARK) ? th.palette.grey[900] : th.palette.grey[100]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight={50}
      flexGrow={1}
      ref={provided.innerRef}
      {...provided.droppableProps}
      style={{
        backgroundColor: snapshot.isDraggingOver ? selectDraggingBg() : "",
        transition: "background-color 0.2s ease",
      }}
    >
      {body}
    </Box>
  );
};

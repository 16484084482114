import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";

import { ModelActionButton, SelectButton } from "../../buttons/index.js";

export default ({
  item, callback, use, buttons, chip, model, modelDelete, showEdit = true, showUse = true, showRemove = true,
}) => (
  <>
    <Box
      flex="1 0"
      sx={{
        overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", minWidth: 0,
      }}
    >
      {chip}
    </Box>
    <Box display="flex" justifyContent="flex-end">
      {buttons}
      {showRemove && (
      <ModelActionButton
        remove={modelDelete}
        modelId={item._id}
        fontSize="inherit"
        buttonSize="small"
        callback={callback}
      />
      )}
      {showEdit && (
      <ModelActionButton
        type="edit"
        variant="iconbutton"
        model={model}
        tooltip="edit"
        modelId={item._id}
        fontSize="inherit"
        buttonSize="small"
        callback={callback}
      />
      )}
      {showUse && _.isFunction(use) && <SelectButton func={() => use(item)} />}
    </Box>
  </>
);

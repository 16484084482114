import { Edit } from "@mui/icons-material";
import {
  Box, IconButton, Tooltip, useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { AvatarCircle } from "../../../shared/other/index.js";
import UserProfileAvatarFormDialog from "../UserProfileAvatarFormDialog/index.js";

const UserProfileAvatarBox = React.memo(({ user, callback, edit = true }) => {
  const actions = {};
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexWrap="wrap" width="100%" position="relative">
      <Box position="relative">
        <Box position="relative">
          <AvatarCircle
            user={user}
            style={{
              width: 200, height: 200, fontSize: 60, border: `10px solid ${theme.palette.background.default}`,
            }}
          />
        </Box>
        {edit && (
          <Box style={{
            position: "absolute", bottom: -10, left: "calc(50% - 23px)", backgroundColor: theme.palette.background.default, borderRadius: 25,
          }}
          >
            <Tooltip title={t("edit")} placement="bottom" disableTouchListener>
              <IconButton color="primary" component="span" onClick={() => actions.open()}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <UserProfileAvatarFormDialog actions={actions} callback={callback} />
    </Box>
  );
});

export default UserProfileAvatarBox;

import { Box, Divider, Paper } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";

import { entryBetweenDatesByPlanIdGet } from "../../../services/api/entry.js";
import { useLoggedUserLocale } from "../../../services/hooks/index.js";
import { setDateByInterval } from "../../../services/utils/index.js";
import { GenericError, Loading } from "../../shared/other/index.js";
import EntryBox from "./_entry-box.js";
import Header from "./_header.js";
import setDataByInterval from "./_set-data.js";

export default React.memo(({
  plan, callback, planIdx, actions, reload, isShared = false,
}) => {
  const [data, setData] = React.useState([]);
  const { getLoggedUserLocale } = useLoggedUserLocale();
  const [rows, setRows] = React.useState(_.range(-1, 4));
  const {
    loading, payload, query, error, status,
  } = useQuery(entryBetweenDatesByPlanIdGet(
    plan._id,
    setDateByInterval(plan.interval, 0, new Date(), rows[0]).toISOString(),
    setDateByInterval(plan.interval, 1, new Date(), rows[4]).toISOString(),
  ));

  actions[plan._id] = React.useCallback(() => query(), [query]);

  React.useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload.entries)) {
      setDataByInterval(plan, payload, setData, rows, getLoggedUserLocale);
    }
  }, [payload, loading]);

  const canEdit = () => {
    if (isShared) {
      if (plan.isEditing) {
        return true;
      }
      return false;
    }

    return true;
  };

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box display="flex" flexWrap="wrap" width="100%" position="relative">
      {loading && <Loading />}
      <Paper style={{ width: "100%", marginBottom: 20 }}>
        <Box display="flex" flexDirection="column">
          <Header plan={plan} setRows={setRows} rows={rows} callback={callback} canEdit={canEdit()} isShared={isShared} actions={actions} />
          <Box flex="1 0 100%">
            <Divider variant="middle" style={{ marginBottom: 10, marginLeft: 0, marginRight: 0 }} />
          </Box>
          <Box flex="1 0 100%" display="flex" flexWrap="wrap" padding="0px 5px 5px 5px">
            <EntryBox plan={plan} planIdx={planIdx} reload={reload} data={data} rows={rows} canEdit={canEdit()} isShared={isShared} planCallback={query} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
});

/* eslint-disable react/no-danger */
/* eslint-disable no-alert */
import {
  Alert,
  Box, Button, Divider, Link, Typography, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { userMeGet } from "../../../services/api/user.js";
import { roles } from "../../../services/enums/index.js";
import { useRoleValidator, useSizeStyle } from "../../../services/hooks/index.js";
import { CustomEditButton, SwitchToCoachRoleButton, SwitchToCustomerRoleButton } from "../../shared/buttons/index.js";
import { DangerousHtmlDesc, GenericError, Loading } from "../../shared/other/index.js";
import UserProfileAboutMeFormDialog from "./UserProfileAboutMeFormDialog/index.js";
import UserProfileAvatarBox from "./UserProfileAvatarBox/index.js";
import UserProfileBgBox from "./UserProfileBgBox/index.js";
import UserProfileCardFormDialog from "./UserProfileCardFormDialog/index.js";
import UserProfileEmailChangeFormDialog from "./UserProfileEmailChangeFormDialog/index.js";
import UserProfileLinkFormDialog from "./UserProfileLinkFormDialog/index.js";
import UserProfileNameFormDialog from "./UserProfileNameFormDialog/index.js";
import UserProfilePasswordFormDialog from "./UserProfilePasswordFormDialog/index.js";

export default React.memo(() => {
  const passwordActions = {};
  const emailChangeActions = {};
  const [data, setData] = React.useState(null);
  const { t } = useTranslation();
  const {
    loading, payload, error, query, status,
  } = useQuery(userMeGet());
  const theme = useTheme();
  const { isRole } = useRoleValidator();
  const AvatarPos = useSizeStyle({
    xs: { position: "absolute", top: 180, left: "calc(50% - 110px)" },
    sm: { position: "absolute", top: 180, left: "calc(50% - 110px)" },
    md: { position: "absolute", top: 180, left: 35 },
    lg: { position: "absolute", top: 180, left: 35 },
    xl: { position: "absolute", top: 180, left: 35 },
  });
  const NamePos = useSizeStyle({
    xs: { width: "100%", textAlign: "center", marginTop: 90 },
    sm: { width: "100%", textAlign: "center", marginTop: 90 },
    md: { position: "absolute", bottom: -70, left: 255 },
    lg: { position: "absolute", bottom: -70, left: 255 },
    xl: { position: "absolute", bottom: -70, left: 255 },
  });
  const MarginTop = useSizeStyle({
    xs: { marginTop: 35 },
    sm: { marginTop: 35 },
    md: { marginTop: 120 },
    lg: { marginTop: 120 },
    xl: { marginTop: 120 },
  });
  const NameBox = useSizeStyle({
    xs: {
      position: "absolute", bottom: -40, left: "calc(50% - 20px)", backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    sm: {
      position: "absolute", bottom: -40, left: "calc(50% - 20px)", backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    md: {
      position: "absolute", top: 0, right: -50, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    lg: {
      position: "absolute", top: 0, right: -50, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    xl: {
      position: "absolute", top: 0, right: -50, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
  });
  const FlexLeftCol = useSizeStyle({
    xs: "1 0 100%", sm: "1 0 100%", md: "1 0 33%", lg: "1 0 33%", xl: "1 0 33%",
  });
  const FlexRightCol = useSizeStyle({
    xs: "1 0 100%", sm: "1 0 100%", md: "1 0 66%", lg: "1 0 66%", xl: "1 0 66%",
  });

  const AboutMeOrder = useSizeStyle({
    xs: 1, sm: 1, md: 3, lg: 3, xl: 3,
  });

  React.useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload.data)) {
      setData(payload.data);
    }
  }, [payload, loading]);

  const isName = () => !_.isNil(data) && !_.isNil(data.name) && !_.isEmpty(data.name);
  const isAboutMe = () => !_.isNil(data) && !_.isNil(data.aboutMe) && !_.isEmpty(data.aboutMe);
  const getCardFieldVal = key => (_.isNil(data) || _.isNil(data.card) || _.isEmpty(data.card[key]) ? "-" : data.card[key]);
  const getLinkFieldVal = key => (_.isNil(data) || _.isNil(data.link) || _.isEmpty(data.link[key]) ? "-" : data.link[key]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box display="flex" flexWrap="wrap" width="100%" position="relative">
      {loading && <Loading />}
      <Box style={{ position: "relative" }} width="100%">
        <UserProfileBgBox user={data} callback={query} />
        <Box style={AvatarPos.select()}>
          <UserProfileAvatarBox user={data} callback={query} />
        </Box>
        <Box style={NamePos.select()}>
          <Box position="relative">
            <Box style={NameBox.select()}>
              <CustomEditButton DialogComponent={UserProfileNameFormDialog} callback={query} />
            </Box>
            {isName() && <Typography variant="h2">{data.name}</Typography>}
            {!isName() && <Typography variant="h2" style={{ color: "#777c7f" }}>...</Typography>}
          </Box>
        </Box>
      </Box>
      <Box display="flex" width="100%" style={MarginTop.select()} flexWrap="wrap">
        <Box flex={FlexLeftCol.select()} order={2}>

          <Box style={{ width: "calc(100% - 20px)", margin: 10 }} position="relative">
            <Box style={{
              position: "absolute", top: 0, right: -5, backgroundColor: theme.palette.background.default, borderRadius: 25,
            }}
            >
              <CustomEditButton DialogComponent={UserProfileCardFormDialog} callback={query} />
            </Box>
            <Typography variant="h5">{t("card")}</Typography>
            <Divider style={{ marginBottom: 12 }} />
            <Typography>
              {t("age")}
              :
              {" "}
              {getCardFieldVal("age")}
            </Typography>
            <Typography>
              {t("weight")}
              :
              {" "}
              {getCardFieldVal("weight")}
            </Typography>
            <Typography>
              {t("human_height")}
              :
              {" "}
              {getCardFieldVal("height")}
            </Typography>
            <Typography>
              {t("sport_club")}
              :
              {" "}
              {getCardFieldVal("club")}
            </Typography>
            <Typography>
              {t("experience")}
              :
              {" "}
              {getCardFieldVal("exp")}
            </Typography>
          </Box>

          <Box style={{ width: "calc(100% - 20px)", margin: 10 }} position="relative">
            <Box style={{
              position: "absolute", top: 0, right: -5, backgroundColor: theme.palette.background.default, borderRadius: 25,
            }}
            >
              <CustomEditButton DialogComponent={UserProfileLinkFormDialog} callback={query} />
            </Box>
            <Typography variant="h5">{t("links")}</Typography>
            <Divider style={{ marginBottom: 12 }} />
            {_.map([
              { label: "Instagram", key: "instagram" },
              { label: "Facebook", key: "facebook" },
              { label: "Twitter/X", key: "twitter" },
              { label: t("your_website"), key: "website" },
            ], item => (
              <Typography key={_.lowerCase(item.key)}>
                {item.label}
                :
                {_.isEqual(getLinkFieldVal(item.key), "-") ? getLinkFieldVal(item.key) : <Link href={getLinkFieldVal(item.key)} target="_blank" rel="noopener noreferrer">{t("open_it")}</Link>}
              </Typography>
            ))}
          </Box>

          <Box style={{ width: "calc(100% - 20px)", margin: 10, marginTop: 20 }}>
            <Typography variant="h5">{t("settings")}</Typography>
            <Divider style={{ marginBottom: 12 }} />
            <Box display="flex" width="100%">
              <Box flex="1" display="flex" alignItems="center">
                <Typography>
                  {t("email")}
                  :
                  {" "}
                  {!_.isNil(data) && !_.isNil(data.email) ? data.email : ""}
                </Typography>
              </Box>
              <Box flex="0">
                <Button color="primary" onClick={() => emailChangeActions.create()}>{t("change")}</Button>
              </Box>
              <UserProfileEmailChangeFormDialog actions={emailChangeActions} />
            </Box>
            <Box display="flex" width="100%">
              <Box flex="1" display="flex" alignItems="center">
                <Typography>
                  {t("password")}
                  : ******
                </Typography>
              </Box>
              <Box flex="0">
                <Button color="primary" onClick={() => passwordActions.create()}>{t("change")}</Button>
              </Box>
              <UserProfilePasswordFormDialog actions={passwordActions} />
            </Box>
            <Box display="flex" width="100%">
              <Box flex="1" display="flex" alignItems="center" />
              <Box flex="0">
                <Button color="secondary" style={{ whiteSpace: "nowrap" }} onClick={() => window.alert("Write to us: office@indefit.app")}>{t("remove_account")}</Button>
              </Box>
            </Box>
            {isRole(roles.CUSTOMER) && (
              <Box display="flex" width="100%" flexWrap="wrap">
                <Box flex="1 0 100%">
                  <Alert>{t("switch_to_coach_account_desc")}</Alert>
                </Box>
                <Box flex="1 0 100%" textAlign="center">
                  <SwitchToCoachRoleButton />
                </Box>
              </Box>
            )}
            {isRole(roles.COACH) && (
              <Box display="flex" width="100%" flexWrap="wrap">
                <Box flex="1 0 100%">
                  <Alert>{t("switch_to_customer_account_desc")}</Alert>
                </Box>
                <Box flex="1 0 100%" textAlign="center">
                  <SwitchToCustomerRoleButton />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box flex={FlexRightCol.select()} order={AboutMeOrder.select()}>
          <Box style={{ width: "calc(100% - 20px)", margin: 10 }} position="relative">
            <Box style={{
              position: "absolute", top: 0, right: -5, backgroundColor: theme.palette.background.default, borderRadius: 25,
            }}
            >
              <CustomEditButton DialogComponent={UserProfileAboutMeFormDialog} callback={query} />
            </Box>
            <Typography variant="h5">{t("about_me")}</Typography>
            <Divider style={{ marginBottom: 12 }} />
            {isAboutMe() && (
            <Box sx={{
              wordBreak: "break-word", whiteSpace: "break-spaces", textAlign: "justify",
            }}
            >
              <DangerousHtmlDesc text={data?.aboutMe} />
            </Box>
            )}
            {!isAboutMe() && <Typography variant="inherit" style={{ fontWeight: 100, textAlign: "justify", color: "#777c7f" }}>...</Typography>}
          </Box>
        </Box>

      </Box>
    </Box>
  );
});

import { Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert, Box, Stack, TextField,
} from "@mui/material";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../services/api/index.js";
import { messageDataPost, messageFilterByEntryAndSubordinateGet } from "../../../services/api/message.js";
import { GenericError, Loading } from "../../shared/other/index.js";
import Item from "./_item.js";

export default memo(({ entryId, subordinateId }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const {
    loading, error, status, payload, query,
  } = useQuery(messageFilterByEntryAndSubordinateGet(entryId, subordinateId));

  const Mutation = useMutation(apiAction);

  useEffect(() => {
    if (!_.isNil(payload?.messages?.data)) {
      setMessages(payload?.messages?.data);
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box display="flex" flexDirection="column">
      {loading && <Loading />}
      <Stack spacing="5px">
        <TextField
          value={message}
          onChange={e => setMessage(e.currentTarget.value)}
          multiline
          label={t("your_message")}
          rows={3}
          fullWidth
        />
        <LoadingButton
          loading={Mutation.loading}
          endIcon={<Send />}
          onClick={async () => {
            if (message.length > 0) {
              await Mutation.mutate(...messageDataPost({
                content: message,
                entryId,
                subordinateId,
              }));
              setMessage("");
              query();
            }
          }}
        >
          {t("send")}
        </LoadingButton>
        {messages.length === 0 && <Alert severity="info" variant="outlined">{t("no_messages")}</Alert>}
        {messages.map(item => <Item key={item._id} message={item} callback={query} />)}
      </Stack>
    </Box>
  );
});

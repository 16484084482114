import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { intervals } from "../../../../services/enums/index.js";
import { useGenericInputStyle } from "../../../../services/hooks/index.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, disabled = false,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <InputLabel htmlFor="interval">{t("type")}</InputLabel>
      <Select
        id="interval"
        label={t("type")}
        name="interval"
        value={form.interval || ""}
        onChange={e => setFieldValue("interval", e.target.value)}
        error={isFieldError("interval")}
        disabled={disabled}
      >
        <MenuItem value={intervals.DAILY}>{t("daily")}</MenuItem>
        {/* <MenuItem value={intervals.WEEKLY}>{t("weekly")}</MenuItem> */}
        {/* <MenuItem value={intervals.MONTHLY}>{t("monthly")}</MenuItem> */}
        <MenuItem value={intervals.STATIC}>{t("without_cycles_static")}</MenuItem>
        <MenuItem value={intervals.COMPETITION}>{t("competition")}</MenuItem>
      </Select>
      <FormHelperText error={isFieldError("interval")}>{isFieldError("interval") ? fieldErrorMessage("interval") : t("type_desc")}</FormHelperText>
    </FormControl>
  );
};

import {
  Alert, AlertTitle, Box, InputAdornment, TextField,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import { userMeGet } from "../../../services/api/user.js";
import { intervals } from "../../../services/enums/index.js";
import { financeUrl } from "../../../services/routes.js";
import { safeUpperCase } from "../../../services/utils/index.js";
import { CheckboxField, MultilineTextField } from "../../shared/fields/index.js";
import { GenericError, Loading } from "../../shared/other/index.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue,
}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const {
    loading, payload, error, status,
  } = useQuery(userMeGet());

  useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload.data)) {
      setUser(payload.data);
    }
  }, [payload, loading]);

  if (error && status !== 404) {
    return <GenericError status={status} />;
  }

  if (loading) {
    return <Box position="relative"><Loading /></Box>;
  }

  return (
    <Alert severity="info">
      <AlertTitle>{t("finance_info_title")}</AlertTitle>
      {(_.isNil(user.stripe) || user.stripe.onboardingSuccess === false) && (
        <Trans i18nKey="finance_info_plan_desc">
          this_will_be_translated
          <Link to={financeUrl()}>{t("finance")}</Link>
          </Trans>
      )}
      {!_.isNil(user.stripe) && user.stripe.onboardingSuccess === true && (
        <Box display="flex" flexWrap="wrap">
          <Box flex="1 0 100%">
            <Trans i18nKey="finance_info_plan_price_desc" />
          </Box>
          <Box flex="1 0 100%">
            <NumberFormat
              style={{ marginTop: "20px" }}
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              allowedDecimalSeparators={[".", ","]}
              type="text"
              error={isFieldError("price")}
              helperText={fieldErrorMessage("price")}
              name="price"
              label={t("price")}
              InputProps={{
                startAdornment: <InputAdornment position="start">{safeUpperCase(user.stripe.currency)}</InputAdornment>,
              }}
              value={form.price}
              onChange={e => {
                setFieldValue("price", e.target.value);
              }}
            />
          </Box>
          {form.interval !== intervals.COMPETITION && (
            <>
              <CheckboxField
                label="available_in_my_shop"
                fieldName="inShop"
                form={form}
                setFieldValue={setFieldValue}
              />
              {form.inShop && (
                <MultilineTextField
                  minRows={2}
                  maxRows={20}
                  label="description"
                  fieldName="shopDesc"
                  form={form}
                  isFieldError={isFieldError}
                  fieldErrorMessage={fieldErrorMessage}
                  setFieldValue={setFieldValue}
                />
              )}
            </>
          )}
        </Box>
      )}
    </Alert>
  );
};

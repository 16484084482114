import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { intervals, models } from "../../../services/enums/index.js";
import { ModelActionButton } from "../../shared/buttons/index.js";

export default ({
  plan, col, colIdx, rows, canEdit = true, planCallback,
}) => {
  const { t } = useTranslation();

  const color = () => (col.present ? "secondary" : "inherit");

  const makeBox = (title, subtitle) => (
    <Box display="flex" textAlign="center">
      <Box flex="1" display="flex" flexDirection="column" justifyContent="center">
        <Typography color={color()} variant="body1" style={{ textTransform: "capitalize" }}>
          {title}
        </Typography>
        <Typography color={color()} variant="caption">
          {subtitle}
        </Typography>
      </Box>
      {canEdit && (
      <Box flex="0" display="flex" flexDirection="column" justifyContent="center" marginLeft="10px">
        <ModelActionButton
          type="add"
          variant="iconbutton"
          model={models.ENTRY}
          tooltip="add_entry"
          initData={{
            isScore: _.isEqual(plan.interval, intervals.COMPETITION),
            date: col.iso,
            planId: plan._id,
            planName: plan.name,
            planInterval: plan.interval,
            order: col.items.length + 1,
            day: rows[colIdx] + 2,
          }}
          callback={planCallback}
          placement="bottom"
        />
      </Box>
      )}
    </Box>
  );

  switch (plan.interval) {
    case intervals.DAILY:
      return makeBox(t(`d_${col.day}`), col.date);
    case intervals.WEEKLY:
      return makeBox(col.startOfWeek, col.endOfWeek);
    case intervals.MONTHLY:
      return makeBox(t(`m_${col.month - 1}`), col.year);
    case intervals.COMPETITION:
    case intervals.STATIC:
      return makeBox(`${_.isNil(plan.periodName) ? t("day") : t(plan.periodName)} ${rows[colIdx] + 2}`);
    default:
      return "";
  }
};

import { EventAvailable } from "@mui/icons-material";
import { Alert } from "@mui/material";
import _ from "lodash";
import React from "react";

import EventEntryItem from "./_event-entry-item.js";

export default ({
  t, plan, idx, entries,
}) => (
  <>
    {!plan.hiddenDays && (
    <Alert
      icon={<EventAvailable />}
      severity="info"
      variant="filled"
      style={{ width: "calc(100% - 10px)", margin: "20px 5px", backgroundColor: "cadetblue" }}
    >
      {_.isNil(plan.periodName) ? t("day") : t(plan.periodName)}
      {" "}
      {idx + 1}
    </Alert>
    )}
    {entries.map((item, idx2) => <EventEntryItem key={`entry_${idx}_${idx2}`} t={t} item={item} />)}
  </>
);

import _ from "lodash";
import React from "react";

import { createFuncDef, funcCaller } from "../utils/index.js";
import { useFormQuery, useOpenCloseControl } from "./index.js";

/**
 * Generic form engine to use for all kind of models
 *
 * @param {Object} actions Reference to parent object with functions
 * @param {Function} createFunc Function to create api "create" request
 * @param {Function} updateFunc Function to create api "update" request
 * @param {Function} queryFunc Function to query data by id, for editing
 * @param {Object} formInit Initial form values
 * @param {Object} formSchema Yup schema (see https://github.com/jquense/yup)
 * @param {Array} onSuccessCallbacks Array of functions to run when form will be successfully submitted
 * @param {Array|Function} onLoadCallbacks Array of function to run on queryFunc or function, which returns an array of functions
 * @param {Array} onEditCallbacks Array of function to run on successful edit
 * @param {Array} beforeSubmitFunc Function to manipulate data just before send
 * @param {Array} onOpenCallbacks Array of function to run on open
 * @returns {Object} { form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, isEditing, errors, isOpen, handleClose, isFieldError, fieldErrorMessage }
 */
export default (
  actions, createFunc, updateFunc, queryFunc, formInit, formSchema, onSuccessCallbacks = [],
  onLoadCallbacks = [], onEditCallbacks = [], beforeSubmitFunc = null, onOpenCallbacks = [],
) => {
  const [form, setForm] = React.useState(formInit);
  const [formErrors, setFormErrors] = React.useState({});
  const [isEditing, setIsEditing] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  // it has to be handled this way as some of the form activities relies on time
  const [monitorChanges, setMonitorChanges] = React.useState(false);
  const {
    isOpen, handleOpen, handleClose, handleCloseNoConfirm,
  } = useOpenCloseControl(onOpenCallbacks, [], changed);

  actions.create = data => {
    setMonitorChanges(false);
    setChanged(false);
    // Hack to be sure, that form will be initialized correctly.
    setTimeout(() => setForm({ ...formInit, ...data }), 100);
    setTimeout(() => setMonitorChanges(true), 1000);
    setFormErrors({});
    reset();
    setIsEditing(false);
    handleOpen();
  };

  actions.edit = id => {
    setMonitorChanges(false);
    setChanged(false);
    // Hack to be sure, that "load" will do the last state change.
    // If possible, find a better way to handle it. Problem is mostly in Entry module
    setTimeout(() => load(id), 100);
    setTimeout(() => setMonitorChanges(true), 1000);
    setFormErrors({});
    reset();
    funcCaller(onEditCallbacks);
    setIsEditing(true);
    handleOpen();
  };

  const setFieldValue = (key, value) => {
    setForm({ ...form, [key]: value });

    if (monitorChanges) {
      setChanged(true);
    }
  };

  const setFieldValues = values => {
    setForm({ ...form, ...values });
  };

  const {
    submit, isLoading, isError, errors, load, reset,
  } = useFormQuery(
    createFunc, updateFunc, queryFunc, [createFuncDef(handleCloseNoConfirm)].concat(onSuccessCallbacks),
    onLoadCallbacks, beforeSubmitFunc, setFieldValues,
  );

  const handleSubmit = () => {
    formSchema.validate(form, { abortEarly: false })
      .then(submitForm)
      .catch(submitErrors);
  };

  const submitForm = async validData => {
    setFormErrors({});
    const res = await submit(validData);

    if (!res) {
      setForm(formInit);
    }
  };

  const submitErrors = err => {
    const errors = {};
    if (err.inner) {
      err.inner.forEach(item => {
        if (!_.isNil(item.path)) {
          errors[item.path] = item.errors;
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    setFormErrors(errors);
  };

  const isFieldError = fieldName => !_.isNil(formErrors[fieldName]);
  const fieldErrorMessage = fieldName => (_.isNil(formErrors[fieldName]) ? "" : formErrors[fieldName]);
  const getFormSchema = () => formSchema;

  return {
    form, isLoading, isError, isEditing, errors, isOpen, handleClose, submitForm, submitErrors,
    setFieldValue, setFieldValues, handleSubmit, isFieldError, fieldErrorMessage, getFormSchema,
  };
};

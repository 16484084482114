import {
  Badge, Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography,
} from "@mui/material";
import React from "react";

import { createUserDisplayName } from "../../../../../services/utils/index.js";
import { AvatarCircle } from "../../../../shared/other/index.js";

export default ({ item }) => (
  <>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Badge
          variant="standard"
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={item.place}
        >
          <AvatarCircle user={item.user} />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={(
          <>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 600 }} color={item.user.itsMe ? "primary" : "inherit"}>
                  {createUserDisplayName(item.user)}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="caption"
              style={{
                whiteSpace: "pre-line", marginTop: 10, width: "calc(100% - 20px)", textAlign: "justify", wordBreak: "break-word",
              }}
            >
              {item.content}
            </Typography>
          </>
        )}
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </>
);

import React from "react";
import { useTranslation } from "react-i18next";

import { planShareDataPost, planShareDataPut, planShareOneGet } from "../../../services/api/plan-share.js";
import { useFormEngine } from "../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../services/utils/index.js";
import { CheckboxField, TextField } from "../../shared/fields/index.js";
import { FormDialogTemplate } from "../../shared/templates/index.js";
import AddCustomerButton from "./_add-customer-button.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

export default React.memo(({ actions = {}, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, isEditing, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, planShareDataPost, planShareDataPut, planShareOneGet, {
    email: "",
    isEditing: false,
    planId: null,
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="share"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      titleButtons={!isEditing && <AddCustomerButton setFieldValue={setFieldValue} />}
      body={(
        <>
          <TextField
            disabled={isEditing}
            label="email"
            fieldName="email"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <CheckboxField
            fieldName="isEditing"
            label="allow_editing"
            form={form}
            setFieldValue={setFieldValue}
            helper={t("allow_editing_desc")}
          />
        </>
)}
    />
  );
});

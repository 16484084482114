import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { periods } from "../../../../services/enums/index.js";
import { useGenericInputStyle } from "../../../../services/hooks/index.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, disabled = false,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <InputLabel htmlFor="periodName">{t("period")}</InputLabel>
      <Select
        id="periodName"
        label={t("interval")}
        name="periodName"
        value={form.periodName || ""}
        onChange={e => setFieldValue("periodName", e.target.value)}
        error={isFieldError("periodName")}
        disabled={disabled}
      >
        <MenuItem value={periods.DAY}>{t("day")}</MenuItem>
        <MenuItem value={periods.WEEK}>{t("week")}</MenuItem>
        <MenuItem value={periods.STAGE}>{t("stage")}</MenuItem>
      </Select>
      <FormHelperText error={isFieldError("periodName")}>{fieldErrorMessage("periodName")}</FormHelperText>
      <FormHelperText>{t("period_field_desc")}</FormHelperText>
    </FormControl>
  );
};

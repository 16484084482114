import { useTheme } from "@emotion/react";
import {
  Box,
  Card, CardActions,
  CardContent, CardMedia, Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import { fileBlobGet } from "../../../services/api/file.js";
import { PaymentButton } from "../../shared/buttons/index.js";

export default ({ coach, plan, fullWidth = false }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box flex={`0 0 ${fullWidth ? 100 : 50}%`} sx={{ padding: "10px" }} display="flex">
      <Card sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {plan.bg && (
        <CardMedia
          component="img"
          alt={plan.name}
          height="140"
          image={fileBlobGet(isXs && plan.mobileBg ? plan.mobileBg : plan.bg)}
        />
        )}
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography gutterBottom variant="h5" component="div">
            {plan.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {plan.shopDesc}
          </Typography>
        </CardContent>
        <Box flexGrow={1} flexBasis="100%" />
        <CardActions>
          <PaymentButton planId={plan._id} currency={coach.stripe.currency} price={plan.price} />
        </CardActions>
      </Card>
    </Box>
  );
};

import { Avatar } from "@mui/material";
import _ from "lodash";
import React from "react";

import { fileBlobGet } from "../../../../services/api/file.js";
import { createUserDisplayName, safeUpperCase } from "../../../../services/utils/index.js";

export default ({ user, style }) => (
  <Avatar
    variant="circular"
    style={{ ...style }}
    alt={_.isNil(user) ? "" : user.name}
    src={_.isNil(user) || _.isNil(user.avatar) ? "" : fileBlobGet(user.avatar)}
  >
    {_.isNil(user) || _.isEmpty(user) ? "" : safeUpperCase(createUserDisplayName(user).substr(0, 1))}
  </Avatar>
);

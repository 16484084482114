import { createFuncDef } from "../../../services/utils/index.js";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      name: data.name,
      scoreUnit: data.scoreUnit,
    });
  }),
];

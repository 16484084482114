/* eslint-disable camelcase */
import React from "react";

import { userMeGet, userMePut } from "../../../../services/api/user.js";
import { useFormEngine } from "../../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../../services/utils/index.js";
import { TextField } from "../../../shared/fields/index.js";
import { FormDialogTemplate } from "../../../shared/templates/index.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

const UserProfileCardFormDialog = React.memo(({ actions, callback }) => {

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, null, userMePut, userMeGet, {
    // Double floor is important here. It creates a nested json later.
    card__age: "",
    card__weight: "",
    card__height: "",
    card__club: "",
    card__exp: "",
  }, formSchema(), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="card"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            label="age"
            fieldName="card__age"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="weight"
            fieldName="card__weight"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="human_height"
            fieldName="card__height"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="sport_club"
            fieldName="card__club"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="experience"
            fieldName="card__exp"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    />
  );
});

export default UserProfileCardFormDialog;

/* eslint-disable no-unused-vars */
import {
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";

import { userStripeOnboardingGet } from "../../../services/api/user.js";
import { GenericError, Loading } from "../../shared/other/index.js";
import Connect from "./_connect.js";
import StripePanel from "./_stripe-panel.js";

export default React.memo(() => {
  const [onboarding, setOnboarding] = useState(false);
  const {
    loading, payload, error, status,
  } = useQuery(userStripeOnboardingGet());

  useEffect(() => {
    if (!loading && payload) {
      setOnboarding(payload.onboardingSuccess);
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError status={status} />;
  }

  if (loading) {
    return <Box position="relative"><Loading /></Box>;
  }

  if (onboarding) {
    return <Box><StripePanel /></Box>;
  }
  return <Connect />;
});

/* eslint-disable no-console */
import "./index.css";

import i18next from "i18next";
import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { ClientContextProvider } from "react-fetching-library";
import { I18nextProvider } from "react-i18next";

import { Error500, Main } from "./routes/index.js";
import { client } from "./services/api/index.js";
import { getLocale, logError } from "./services/utils/index.js";
import translationsEN from "./translations/en.json";
import translationsPL from "./translations/pl.json";

const originalWarn = console.warn;
const originalErr = console.error;

console.warn = (message, ...args) => {
  if (message.includes("Support for defaultProps will be removed")) {
    return;
  }
  originalWarn(message, ...args);
};

console.error = (message, ...args) => {
  if (message.includes("Support for defaultProps will be removed")) {
    return;
  }
  originalErr(message, ...args);
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: window.localStorage.language || getLocale().language,
  fallbackLng: "en",
  resources: {
    pl: { translation: translationsPL },
    en: { translation: translationsEN },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
  },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render((
  <ClientContextProvider client={client}>
    <I18nextProvider i18n={i18next}>
      <ErrorBoundary fallback={<Error500 />} onError={logError}>
        <Main />
      </ErrorBoundary>
    </I18nextProvider>
  </ClientContextProvider>
));

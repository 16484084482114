import { addDays } from "date-fns";
import _ from "lodash";

import { fixed, intervals } from "../../../services/enums/index.js";

export default formData => {
  const mData = _.cloneDeep(formData);
  mData.media = _.map(mData.media, item => item._id);
  mData.date = _.includes([intervals.COMPETITION, intervals.STATIC], formData.planInterval) ? addDays(new Date(fixed.STATIC_BLOCK_START_TIME), mData.day - 2) : mData.date;

  return mData;
};

import _ from "lodash";

import { localStorageDict } from "../enums/index.js";
import { useLocalStorage } from "./index.js";

export default featureKey => {
  const [featureFlags] = useLocalStorage(localStorageDict.FEATURE_FLAGS, null);
  const [hostConfig] = useLocalStorage(localStorageDict.HOST_CONFIG, { name: "", website: "", features: [] });

  const isEnabled = () => _.includes(hostConfig.features || [], featureKey) || _.includes(featureFlags || [], featureKey);

  return { isEnabled };
};

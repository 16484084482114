import {
  Alert,
  Badge, Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";

import { intervals, models, roles } from "../../../services/enums/index.js";
import { createUserDisplayName } from "../../../services/utils/index.js";
import { MessageButton } from "../../message/index.js";
import {
  ConfirmedButton, LockButton, ModelActionButton, OpenTabButton,
} from "../../shared/buttons/index.js";
import { AvatarCircle } from "../../shared/other/index.js";
import getValue from "./_get-value.js";
import ItemWithTieBreak from "./_item-with-tie-break.js";
import ItemWithoutTieBreak from "./_item-without-tie-break.js";

export default ({
  item, entry, isRole, plan, reload, queryArgs, canEdit = true,
}) => (
  <>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Badge
          variant="standard"
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={item.place}
        >
          <AvatarCircle user={item.user} />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={(
          <>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 600 }} color={item.user.itsMe ? "primary" : "inherit"}>
                  {getValue(item, entry.scoreUnit)}
                </Typography>
              </Box>
              {isRole(roles.COACH) && (
              <Box justifyContent="flex-end" display="flex">
                {plan.interval !== intervals.COMPETITION && (
                <LockButton
                  enabled={item.public}
                  titleEnabled="score_is_public"
                  titleDisabled="score_is_private"
                  disabled
                  buttonSize="small"
                  fontSize="small"
                />
                )}
                {plan.interval === intervals.COMPETITION && entry.isDeadline && entry.isConfirmationRequired && (
                  <ConfirmedButton
                    enabled={item.isConfirmed}
                    titleEnabled="score_confirmed"
                    titleDisabled="score_not_confirmed"
                    disabled
                    buttonSize="small"
                    fontSize="small"
                  />
                )}
                {entry.isDeadline && !_.isNil(item.videoLink) && !_.isEmpty(item.videoLink) && (
                  <OpenTabButton
                    link={item.videoLink}
                    tooltipTitle="video_link"
                    placement="bottom"
                  />
                )}
                {canEdit && (
                  <ModelActionButton
                    type="edit"
                    modelId={item._id}
                    buttonSize="small"
                    fontSize="small"
                    variant="iconbutton"
                    model={models.SCORE}
                    tooltip="edit"
                    callback={() => reload(queryArgs)}
                    options={{
                      planInterval: plan?.interval, isDeadline: entry.isDeadline, isConfirmationRequired: entry.isConfirmationRequired, isTimeCap: entry.isTimeCap,
                    }}
                  />
                )}
                <MessageButton entryId={entry._id} userName={createUserDisplayName(item.user)} subordinateId={item.user._id} />
              </Box>
              )}
            </Box>
            {plan.interval === intervals.COMPETITION && entry.isTieBreak && (
            <>
              {!_.isNil(item.tieBreak) && !_.isEmpty(item.tieBreak) && (
              <ItemWithTieBreak
                item={item.tieBreak}
                entry={entry}
                isRole={isRole}
                plan={plan}
                reload={reload}
                queryArgs={queryArgs}
                canEdit={canEdit}
              />
              )}
              {(_.isNil(item.tieBreak) || _.isEmpty(item.tieBreak)) && (
              <ItemWithoutTieBreak
                item={item.tieBreak}
                entry={entry}
                user={item.user}
                isRole={isRole}
                plan={plan}
                reload={reload}
                queryArgs={queryArgs}
                canEdit={canEdit}
              />
              )}
            </>
            )}
          </>
        )}
        secondary={(
          <>
            <Typography
              component="span"
              variant="body2"
              color={item.user.itsMe ? "primary" : "inherit"}
            >
              {createUserDisplayName(item.user)}
            </Typography>
            {!_.isNil(item.note) && isRole(roles.COACH) && <Alert variant="outlined" severity="info" icon={false}>{item.note.content ?? ""}</Alert>}
          </>
        )}
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </>
);

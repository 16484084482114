import {
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import GenericNotificationItem from "./_generic-notification-item.js";

export default memo(({ notif }) => {
  const { t } = useTranslation();

  return (
    <GenericNotificationItem notif={notif} title={t("thanks_for_signing_up_for_a_competition_x", { plan: notif.metadata.planName })}>
      <Typography
        sx={{ marginBottom: "5px" }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        {t("follow_the_competition_home_page")}
      </Typography>
    </GenericNotificationItem>
  );
});

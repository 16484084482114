import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { roles } from "../../../../services/enums/index.js";
import { useGenericInputStyle } from "../../../../services/hooks/index.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, variant = "outlined",
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <InputLabel htmlFor="role" style={{ marginTop: "5px", left: "-14px" }}>{t("select_role")}</InputLabel>
      <Select
        id="role"
        variant={variant}
        label={t("select_role")}
        name="role"
        value={form.role || ""}
        onChange={e => setFieldValue("role", e.target.value)}
        error={isFieldError("role")}
      >
        <MenuItem value={roles.COACH_NAME}>{t("trainer")}</MenuItem>
        <MenuItem value={roles.CUSTOMER_NAME}>{t("athlete")}</MenuItem>
      </Select>
      <FormHelperText error={isFieldError("role")}>{isFieldError("role") ?? fieldErrorMessage("role")}</FormHelperText>
    </FormControl>
  );
};

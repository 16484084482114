import {
  Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useOpenCloseControl } from "../../../../services/hooks/index.js";
import { FormDialogActions } from "../../other/index.js";

export default ({
  title, titleExt, use, modelPanel, actions = {}, maxWidth = "md", initData = {}, options = {},
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isOpen, handleOpen, handleClose } = useOpenCloseControl();

  actions.open = () => handleOpen();
  actions.close = () => handleClose();

  const select = item => {
    use(item);
    handleClose();
  };

  const ModelPanel = modelPanel;

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen()}
      onClose={handleClose}
    >
      <DialogTitle>
        {t(title)}
        {" "}
        {titleExt}
      </DialogTitle>
      <DialogContent>
        <ModelPanel use={select} bgFrom="MuiDialog" initData={initData} options={options} />
      </DialogContent>
      <FormDialogActions close={handleClose} />
    </Dialog>
  );
};

import {
  Alert, Box, FormControl,
  InputAdornment, TextField,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

import {
  scoreDataPost, scoreDataPut, scoreOneGet, scoreTieBreakOneGet,
} from "../../../services/api/score.js";
import { intervals, roles, units } from "../../../services/enums/index.js";
import { useFormEngine, useRoleValidator } from "../../../services/hooks/index.js";
import { marginBottomForFields, onSuccessCallbacks } from "../../../services/utils/index.js";
import { CheckboxField } from "../../shared/fields/index.js";
import { FormDialogTemplate } from "../../shared/templates/index.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

export default React.memo(({ actions, callback, options }) => {
  const { t } = useTranslation();
  const { isRole } = useRoleValidator();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, scoreDataPost, scoreDataPut, _.isNil(options.isTieBreak) || !options.isTieBreak ? scoreOneGet : scoreTieBreakOneGet, {
    _id: null,
    entryId: "",
    customerUserId: null,
    scoreUnit: "",
    public: options?.planInterval === intervals.COMPETITION,
    videoLink: "",
    valueA: "",
    valueB: "",
    valueC: "",
    isConfirmed: options?.planInterval !== intervals.COMPETITION,
    isTieBreak: false,
    isTimeCap: false,
    timeCapReps: 0,
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="score"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <FormControl style={{ width: "100%", marginBottom: marginBottomForFields() }}>
            {form.scoreUnit === units.WEIGHT && (
            <NumberFormat
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              allowedDecimalSeparators={[".", ","]}
              type="text"
              error={isFieldError("valueA")}
              helperText={fieldErrorMessage("valueA")}
              name="valueA"
              label={t("weight")}
              InputProps={{
                startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
              }}
              value={form.valueA}
              onChange={e => setFieldValue("valueA", e.target.value)}
            />
            )}
            {form.scoreUnit === units.TIME && (
            <Box width="100%" display="flex" flexWrap="wrap">
              <Box flex="1 0 33%">
                <NumberFormat
                  customInput={TextField}
                  min={0}
                  max={99}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  allowedDecimalSeparators={[]}
                  style={{ marginRight: 2 }}
                  type="text"
                  error={isFieldError("valueA")}
                  helperText={fieldErrorMessage("valueA")}
                  name="valueA"
                  label={t("hours")}
                  value={form.valueA}
                  onChange={e => {
                    const { value } = e.target;

                    if (parseInt(value, 10) > 99) {
                      setFieldValue("valueA", "99");
                    } else {
                      setFieldValue("valueA", value);
                    }
                  }}
                  disabled={form.isTimeCap}
                />
              </Box>
              <Box flex="1 0 33%">
                <NumberFormat
                  customInput={TextField}
                  min={0}
                  max={59}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  allowedDecimalSeparators={[]}
                  style={{ marginLeft: 2, marginRight: 2 }}
                  type="text"
                  error={isFieldError("valueB")}
                  helperText={fieldErrorMessage("valueB")}
                  name="valueB"
                  label={t("minutes")}
                  value={form.valueB}
                  onChange={e => {
                    const { value } = e.target;

                    if (parseInt(value, 10) > 59) {
                      setFieldValue("valueB", "59");
                    } else {
                      setFieldValue("valueB", value);
                    }
                  }}
                  disabled={form.isTimeCap}
                />
              </Box>
              <Box flex="1 0 33%">
                <NumberFormat
                  customInput={TextField}
                  min={0}
                  max={59}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  allowedDecimalSeparators={[]}
                  style={{ marginLeft: 2 }}
                  type="text"
                  error={isFieldError("valueC")}
                  helperText={fieldErrorMessage("valueC")}
                  name="valueC"
                  label={t("seconds")}
                  value={form.valueC}
                  onChange={e => {
                    const { value } = e.target;

                    if (parseInt(value, 10) > 59) {
                      setFieldValue("valueC", "59");
                    } else {
                      setFieldValue("valueC", value);
                    }

                  }}
                  disabled={form.isTimeCap}
                />
              </Box>
              {options.isTimeCap && (
              <Box flex="1 0 100%" display="contents">
                <CheckboxField
                  fieldName="isTimeCap"
                  label="time_cap"
                  form={form}
                  setFieldValue={setFieldValue}
                  helper="time_cap_desc_competitor"
                />
              </Box>
              )}
              {form.isTimeCap && (
              <Box flex="1 0 100%">
                <NumberFormat
                  customInput={TextField}
                  decimalScale={0}
                  min={0}
                  fixedDecimalScale
                  allowNegative={false}
                  allowedDecimalSeparators={[]}
                  allowEmptyFormatting
                  type="text"
                  error={isFieldError("timeCapReps")}
                  helperText={fieldErrorMessage("timeCapReps")}
                  name="timeCapReps"
                  label={t("repetitions")}
                  value={form.timeCapReps}
                  onChange={e => setFieldValue("timeCapReps", e.target.value)}
                  style={{ width: "100%" }}
                />
              </Box>
              )}
            </Box>
            )}
            {form.scoreUnit === units.DISTANCE && (
            <NumberFormat
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              allowedDecimalSeparators={[".", ","]}
              type="text"
              error={isFieldError("valueA")}
              helperText={fieldErrorMessage("valueA")}
              name="valueA"
              label={t("distance")}
              InputProps={{
                startAdornment: <InputAdornment position="start">m</InputAdornment>,
              }}
              value={form.valueA}
              onChange={e => setFieldValue("valueA", e.target.value)}
            />
            )}
            {form.scoreUnit === units.REPS && (
            <NumberFormat
              customInput={TextField}
              decimalScale={0}
              min={0}
              fixedDecimalScale
              allowNegative={false}
              allowedDecimalSeparators={[]}
              allowEmptyFormatting
              type="text"
              error={isFieldError("valueA")}
              helperText={fieldErrorMessage("valueA")}
              name="valueA"
              label={t("repetitions")}
              value={form.valueA}
              onChange={e => setFieldValue("valueA", e.target.value)}
            />
            )}
            {form.scoreUnit === units.ROUNDS && (
            <NumberFormat
              customInput={TextField}
              decimalScale={0}
              min={0}
              fixedDecimalScale
              allowNegative={false}
              allowedDecimalSeparators={[]}
              allowEmptyFormatting
              type="text"
              error={isFieldError("valueA")}
              helperText={fieldErrorMessage("valueA")}
              name="valueA"
              label={t("rounds")}
              value={form.valueA}
              onChange={e => setFieldValue("valueA", e.target.value)}
            />
            )}
            {form.scoreUnit === units.DESCRIPTION && (
            <TextField
              multiline
              minRows={3}
              maxRows={10}
              type="text"
              error={isFieldError("valueA")}
              helperText={fieldErrorMessage("valueA") ? fieldErrorMessage("valueA") : t("any_value")}
              name="valueA"
              label={t("description")}
              value={form.valueA || ""}
              onChange={e => setFieldValue("valueA", e.target.value)}
            />
            )}
          </FormControl>
          {options?.planInterval !== intervals.COMPETITION && (
          <CheckboxField
            label="score_public"
            fieldName="public"
            form={form}
            setFieldValue={setFieldValue}
            helper={t("public_comment")}
          />
          )}
          {options?.planInterval === intervals.COMPETITION && options?.isDeadline && (
          <>
            <FormControl style={{ width: "100%", marginBottom: marginBottomForFields() }}>
              <TextField
                type="text"
                error={isFieldError("videoLink")}
                helperText={fieldErrorMessage("videoLink")}
                name="videoLink"
                label={t("video_link")}
                value={form.videoLink || ""}
                onChange={e => setFieldValue("videoLink", e.target.value)}
                disabled={isRole(roles.COACH)}
              />
            </FormControl>
            {isRole(roles.COACH) && options?.isConfirmationRequired && (
            <Box display="flex" flexWrap="wrap" width="100%">
              <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                <CheckboxField
                  fieldName="isConfirmed"
                  label="competition_score_confirmed"
                  form={form}
                  setFieldValue={setFieldValue}
                  helper={t("competition_score_confirmed_desc")}
                />
              </Box>
            </Box>
            )}
            {isRole(roles.CUSTOMER) && options?.isConfirmationRequired && (
            <Box display="flex" flexWrap="wrap" width="100%">
              <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                <Alert color="info">{t("score_will_appear_on_leaderboard_after_confirmation")}</Alert>
              </Box>
            </Box>
            )}
          </>
          )}
        </>
)}
    />
  );
});

import { Search } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  args = [], actions = {}, previewDialog, placement = "bottom", tooltipTitle = "preview", buttonSize = "small", iconSize = "inherit", icon,
}) => {
  const { t } = useTranslation();

  const PreviewDialog = previewDialog;
  const Icon = icon;

  return (
    <>
      <Tooltip title={t(tooltipTitle)} placement={placement} disableTouchListener>
        <IconButton
          data-testid="preview"
          size={buttonSize}
          onClick={() => {
            actions.open(...args);
          }}
        >
          {_.isNil(icon) ? <Search fontSize={iconSize} /> : <Icon fontSize={iconSize} />}
        </IconButton>
      </Tooltip>

      <PreviewDialog actions={actions} />
    </>
  );
};

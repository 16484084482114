import { Alert, Box, Button } from "@mui/material";
import {
  addDays, differenceInDays, isAfter,
} from "date-fns";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { userMeSubscriptionGet, userStripeCheckoutGet } from "../../../services/api/user.js";

export default () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionTo, setSubscriptionTo] = useState(null);
  const [prices, setPrices] = useState(null);
  const {
    loading, payload, error, status,
  } = useQuery(userMeSubscriptionGet());
  const monthPayment = useQuery(userStripeCheckoutGet("month"), false);
  const yearPayment = useQuery(userStripeCheckoutGet("year"), false);

  useEffect(() => {
    if (_.isNil(payload)) {
      return;
    }

    setSubscriptionTo(new Date(payload.user.subscriptionTo));
    setPrices(payload.prices);
  }, [payload]);

  if (loading || error || status !== 200 || subscriptionTo === null || prices === null) {
    return null;
  }

  const daysLeft = differenceInDays(subscriptionTo, new Date());

  if (isAfter(new Date(), addDays(subscriptionTo, -7))) {
    return (
      <Alert
        severity={daysLeft > 0 ? "warning" : "error"}
        style={{ whiteSpace: "pre-line", wordBreak: "break-word", marginBottom: "20px" }}
      >
        <Box display="flex" flexWrap="wrap">
          <Box flex="1 0 100%" mb={1}>
            {daysLeft > 0 ? t("subscription_coach_ending_in_x_days", { x: daysLeft }) : t("subscription_coach_ended")}
          </Box>
          <Box flex="1 0 100%" display="flex" flexWrap="wrap">
            <Box flex="1 0 50%" textAlign="right">
              <Button
                disabled={monthPayment.loading}
                variant="contained"
                color="success"
                style={{ marginRight: "5px" }}
                onClick={async () => {
                  const { payload, status } = await monthPayment.query();
                  if (status === 200) {
                    window.location.href = payload.url;
                  } else {
                    enqueueSnackbar(t("generic_error"), { variant: "error" });
                  }
                }}
              >
                {`${prices.month} PLN / ${t("month")}`}
              </Button>
            </Box>
            <Box flex="1 0 50%" textAlign="left">
              <Button
                disabled={yearPayment.loading}
                variant="contained"
                color="success"
                style={{ marginRight: "5px" }}
                onClick={async () => {
                  const { payload, status } = await yearPayment.query();
                  if (status === 200) {
                    window.location.href = payload.url;
                  } else {
                    enqueueSnackbar(t("generic_error"), { variant: "error" });
                  }
                }}
              >
                {`${prices.year} PLN / ${t("year")}`}
              </Button>
            </Box>
          </Box>
        </Box>
      </Alert>
    );
  }

  return null;
};

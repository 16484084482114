import {
  Box, FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { competitionEntriesByPlanGet } from "../../../../services/api/competition.js";
import { useGenericInputStyle } from "../../../../services/hooks/index.js";
import { GenericError } from "../../other/index.js";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, size = "medium", useOutsideForm = false, style = {},
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();
  const [data, setData] = React.useState([]);
  const {
    error, status, loading, query,
  } = useParameterizedQuery(competitionEntriesByPlanGet);

  React.useLayoutEffect(() => {
    if (!_.isNil(form.planId) && !loading) {
      query(form.planId, form.category).then(result => {
        setData(result?.payload?.entries?.data || []);

        setTimeout(() => {
          setFieldValue("event", "all");
        }, 120);
      });
    }
  }, [form.planId]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box display={data.length === 0 ? "none" : "inherit"} style={style}>
      <FormControl style={{ ...inputStyle.select(), ...(useOutsideForm && { marginBottom: 5 }) }}>
        <InputLabel htmlFor="event">{t("event_cf")}</InputLabel>
        <Select
          id="event"
          label={t("event_cf")}
          size={size}
          name="event"
          value={form.event || ""}
          onChange={e => setFieldValue("event", e.target.value)}
          error={isFieldError("event")}
        >
          <MenuItem value="all">{t("all_events")}</MenuItem>
          {data.map(item => <MenuItem key={item._id} value={item._id}>{item.title}</MenuItem>)}
        </Select>
        <FormHelperText error={isFieldError("event")}>{fieldErrorMessage("event")}</FormHelperText>
      </FormControl>
    </Box>
  );
};

import { AccountCircle, Lock } from "@mui/icons-material";
import { Box, Button, InputAdornment } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { loginAction } from "../../../../services/api/other.js";
import { localStorageDict } from "../../../../services/enums/index.js";
import { useFormEngine } from "../../../../services/hooks/index.js";
import { createFuncDef, writeStorage } from "../../../../services/utils/index.js";
import { TextField } from "../../fields/index.js";
import { ErrorMessages, Loading } from "../../other/index.js";

export default React.memo(() => {
  const { t, i18n } = useTranslation();

  const formSchema = yup.object().shape({
    email: yup.string().trim()
      .required(t("field_is_required"))
      .email(t("invalid_email_format"))
      .max(1000, "text_too_long"),
    password: yup.string().trim()
      .required(t("field_is_required"))
      .max(1000, "text_too_long"),
  });

  const onSuccessCallbacks = [
    createFuncDef(payload => {
      writeStorage(localStorageDict.JWT, payload.data.token);
      writeStorage(localStorageDict.ROLE, payload.data.role);
      writeStorage(localStorageDict.FEATURE_FLAGS, payload.data.featureFlags);
      writeStorage(localStorageDict.LANGUAGE, payload.data.language);

      if (payload.data?.language) {
        i18n.changeLanguage(payload.data.language);
      }
      writeStorage(localStorageDict.TIME_ZONE, payload.data.timeZone);
      writeStorage(localStorageDict.ROLE_BACK, null);
    }),
  ];

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isFieldError, fieldErrorMessage,
  } = useFormEngine(
    {}, loginAction, null, null, { email: "", password: "" }, formSchema, onSuccessCallbacks,
  );

  return (
    <Box width="100%">
      {isLoading() && <Loading />}
      <form onSubmit={e => e.preventDefault()} noValidate>
        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Box flex="1">
            <TextField
              variant="standard"
              label="email"
              fieldName="email"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box flex="1">
            <TextField
              variant="standard"
              type="password"
              label="password"
              fieldName="password"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <ErrorMessages isError={isError()} errors={errors()} />

          <Box flex="1">
            <Button type="submit" size="small" variant="contained" color="primary" style={{ width: "100%" }} onClick={handleSubmit}>
              {t("login_to")}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
});

import { methods } from "../enums/index.js";
import { apiAction } from "./index.js";

export const entryDataPost = data => ["/entry", methods.POST, data];
export const entryDataPut = data => [`/entry/${data._id}`, methods.PUT, data];
export const entryDraggablePut = data => [`/entry/draggable/${data._id}`, methods.PUT, data];
export const entryDelete = id => [`/entry/${id}`, methods.DELETE];
export const entryOneGet = id => apiAction(`/entry/one/${id}`);
export const entryBetweenDatesByPlanIdGet = (planId, dateStart, dateEnd, sort = "date,order") => apiAction(
  `/entry/filter/between-dates/${planId}/${dateStart}/${dateEnd}?sort=${sort}`,
);
export const entryFilterGet = (search = "", page = 1, limit = 10, sort = "-createdAt", filter = "") => apiAction(
  `/entry/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const entryCustomerBetweenDatesByPlanIdGet = (planId, dateStart, dateEnd) => apiAction(
  `/entry/filter/customer/between-dates/${planId}/${dateStart}/${dateEnd}`,
);

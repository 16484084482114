import { Close } from "@mui/icons-material";
import { Alert, Box, IconButton } from "@mui/material";
import { isBefore } from "date-fns";
import _ from "lodash";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { planFilterGet } from "../../../services/api/plan.js";
import { planShareFilterCoachGet } from "../../../services/api/plan-share.js";
import { localStorageDict, models } from "../../../services/enums/index.js";
import { useLocalStorage } from "../../../services/hooks/index.js";
import { writeStorage } from "../../../services/utils/index.js";
import { PlanCoachBox } from "../../plan/index.js";
import { ModelActionButton } from "../../shared/buttons/index.js";
import { GenericError, Loading } from "../../shared/other/index.js";
import ExtendSubscriptionAlert from "./_extend-subscription-alert.js";
import useUpdateDraggable from "./_use-update-draggable.js";

export default React.memo(() => {
  const actions = {};
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const [sharedData, setSharedData] = React.useState([]);
  const {
    loading, payload, error, status, query,
  } = useQuery(planFilterGet("", 1, 100, "order", "{%22board%22:true}"));
  const planShare = useQuery(planShareFilterCoachGet("", 1, 100, "order", "{%22usePlanId%22:true}"));
  const [subscriptionAlert] = useLocalStorage(localStorageDict.SUBSCRIPTION_ALERT, false);

  const reload = React.useCallback((entry, formData) => {
    if (_.isEqual(entry.planId, formData.planId)) {
      actions[entry.planId]();
    } else {
      actions[entry.planId]();
      actions[formData.planId]();
    }
  }, [actions]);

  const { update, isUpdating } = useUpdateDraggable(reload);

  React.useLayoutEffect(() => {
    setData(payload?.plans?.data || []);
    setSharedData(planShare.payload?.planShares?.data || []);
  }, [payload, planShare.payload]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box position="relative" width="100%">
      {(_.isNil(subscriptionAlert) || !subscriptionAlert) && isBefore(new Date(), new Date("2025-05-01")) && (
        <Alert
          severity="info"
          style={{ whiteSpace: "pre-line", wordBreak: "break-word", marginBottom: "20px" }}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                writeStorage(
                  localStorageDict.SUBSCRIPTION_ALERT,
                  true,
                );
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
        )}
        >
          {t("subscription_coach_info")}
        </Alert>
      )}
      <ExtendSubscriptionAlert />
      {(loading || isUpdating()) && <Loading />}
      <DragDropContext onDragEnd={update}>
        {data.map((plan, idx) => (
          <PlanCoachBox
            key={plan._id}
            plan={plan}
            callback={query}
            planIdx={idx}
            actions={actions}
            reload={reload}
          />
        ))}
        {sharedData.map((plan, idx) => (
          <PlanCoachBox
            key={plan._id}
            plan={plan}
            callback={query}
            planIdx={idx}
            actions={actions}
            reload={reload}
            isShared
          />
        ))}
      </DragDropContext>
      <ModelActionButton type="add" model={models.PLAN} variant="fabh1" tooltip="add_plan" callback={query} />
    </Box>
  );
});

import {
  Alert, Box, List,
} from "@mui/material";
import {
  addDays, addHours, addMinutes, isAfter, isBefore,
} from "date-fns";
import _ from "lodash";
import React, { useEffect } from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { scoreFilterByEntryGet } from "../../../services/api/score.js";
import { intervals, roles } from "../../../services/enums/index.js";
import { useCommonDateFormats, useRoleValidator } from "../../../services/hooks/index.js";
import { setDateByInterval } from "../../../services/utils/index.js";
import {
  GenericError, Loading,
} from "../../shared/other/index.js";
import ItemWithScore from "../ScoreEntryPreviewDialog/_item-with-score.js";
import ItemWithoutScore from "../ScoreEntryPreviewDialog/_item-without-score.js";

const ScoreAlert = ({ children }) => <Alert severity="info" style={{ width: "100%", marginLeft: "10px", marginRight: "10px" }}>{children}</Alert>;

export default ({
  actions, entry, plan, shift = 0, canEdit = true,
}) => {
  const { t } = useTranslation();
  const {
    loading, query, error, status,
  } = useParameterizedQuery(scoreFilterByEntryGet, false);
  const [scores, setScores] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [competitors, setCompetitors] = React.useState([]);
  const { isRole } = useRoleValidator();
  const [scoreDate, setScoreDate] = React.useState(new Date());
  const { formatWholeDate } = useCommonDateFormats();
  const [queryArgs, setQueryArgs] = React.useState([]);

  const reload = queryArgs => query(...queryArgs).then(res => {
    setScores(res.payload?.scores?.data || []);
    setSubscriptions(res.payload?.subscriptions?.data || []);
    setCompetitors(res.payload?.competitors?.data || []);
  });

  actions.reload = () => (_.isEmpty(queryArgs) ? null : reload(queryArgs));

  useEffect(() => {
    let date = new Date();

    if (!_.isNil(plan)) {
      date = addDays(addHours(setDateByInterval(plan.interval, 1, date, 0), _.isNil(plan.scoreShift) ? 0 : plan.scoreShift), shift);
    }
    setScoreDate(date);

    if ((isRole(roles.COACH) || isAfter(new Date(), date)) && plan.interval !== intervals.COMPETITION) {
      const qa = ["", 1, 1000, "", "", entry._id, date.toISOString()];
      setQueryArgs(qa);
      reload(qa);
    }
  }, [entry, plan, shift]);

  return (
    <Box width="100%" display="flex" flexWrap="wrap" justifyContent="center" marginBottom="10px">
      {loading && <Loading />}
      {error && <GenericError status={status} />}

      {!loading && scores.length === 0 && (isAfter(new Date(), scoreDate) || isRole(roles.COACH)) && !_.isEqual(plan.interval, intervals.COMPETITION) && <ScoreAlert>{t("no_scores")}</ScoreAlert>}

      {!loading && scores.length === 0 && !isRole(roles.COACH) && (!_.isNil(plan) && _.isEqual(plan.interval, intervals.COMPETITION)) && <ScoreAlert>{t("scores_not_yet_available")}</ScoreAlert>}

      {isBefore(new Date(), scoreDate) && !isRole(roles.COACH) && plan.interval !== intervals.COMPETITION &&
        <ScoreAlert>{t("scores_will_show_up_after", { date: formatWholeDate(addMinutes(scoreDate, 1)) })}</ScoreAlert>}

      {scores.length > 0 && (
        <List style={{ width: "90%" }}>
          {_.map(scores, (item, idx) => (
            <ItemWithScore
              key={item._id}
              item={item}
              entry={entry}
              idx={idx}
              isRole={isRole}
              plan={plan}
              reload={reload}
              queryArgs={queryArgs}
              canEdit={canEdit}
            />
          ))}
          {isRole(roles.COACH) && _.map(subscriptions, (item, idx) => (
            <ItemWithoutScore
              key={idx}
              entry={entry}
              user={item.customer.user}
              plan={plan}
              reload={reload}
              queryArgs={queryArgs}
              canEdit={canEdit}
            />
          ))}
          {isRole(roles.COACH) && _.map(competitors, (item, idx) => (
            <ItemWithoutScore
              key={idx}
              entry={entry}
              user={item.user}
              plan={plan}
              reload={reload}
              queryArgs={queryArgs}
              canEdit={canEdit}
            />
          ))}
        </List>
      )}
    </Box>
  );
};

import {
  Add, ContentCopy, DeleteOutline, Edit,
} from "@mui/icons-material";
import {
  Fab, IconButton, ListItemIcon, MenuItem, Tooltip, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { models } from "../../../../services/enums/index.js";
import { CompetitorFormDialog } from "../../../competitor/index.js";
import { CustomerFormDialog } from "../../../customer/index.js";
import { EntryFormDialog } from "../../../entry/index.js";
import { MediaFormDialog } from "../../../media/index.js";
import { NoteFormDialog } from "../../../note/index.js";
import { PlanFormDialog } from "../../../plan/index.js";
import { PlanShareFormDialog } from "../../../plan-share/index.js";
import { ScoreFormDialog } from "../../../score/index.js";
import { StatisticFormDialog } from "../../../statistic/index.js";
import { SubscriptionFormDialog } from "../../../subscription/index.js";
import { ConfirmRemoveFormDialog } from "../../dialogs/index.js";

export default React.memo(({
  model, type, variant, tooltip, initData, modelId, modelData, remove, childId, removeAnchor = () => ({}),
  callback, options, style, placement = "bottom", buttonSize = "medium", fontSize = "small", isList = false,
}) => {
  const { t } = useTranslation();
  const actions = {};

  const getModelData = () => {
    const modelDataCopy = JSON.parse(JSON.stringify(modelData));
    switch (model) {
      case models.ENTRY: {
        if (_.isEmpty(modelDataCopy.media) || (modelDataCopy.media.length > 0 && _.isEmpty(modelDataCopy.media[0]))) {
          delete modelDataCopy.media;
        }
        if (_.isNil(modelDataCopy.statisticId)) {
          delete modelDataCopy.statisticId;
          delete modelDataCopy.statisticName;
        }
        delete modelDataCopy._id;
        delete modelDataCopy.planId;
        delete modelDataCopy.createdAt;
        return modelDataCopy;
      }
      default: return modelDataCopy;
    }
  };

  return (
    <>
      {type === "add" && variant === "fabh1" && (
      <Tooltip title={t(tooltip)} placement={placement} disableTouchListener>
        <Typography variant="h1" style={{ textAlign: "center" }}>
          <Fab
            color="primary"
            onClick={() => {
              removeAnchor();
              actions.create(initData);
            }}
          >
            <Add />
          </Fab>
        </Typography>
      </Tooltip>
      )}
      {type === "add" && variant === "fab" && (
      <Tooltip title={t(tooltip)} placement={placement} disableTouchListener>
        <Fab
          color="primary"
          onClick={() => {
            removeAnchor();
            actions.create(initData);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
      )}
      {type === "add" && variant === "iconbutton" && (
      <Tooltip title={t(tooltip)} placement={placement} disableTouchListener>
        <IconButton
          size={buttonSize}
          color="primary"
          onClick={() => {
            removeAnchor();
            actions.create(initData);
          }}
        >
          <Add fontSize={fontSize} />
        </IconButton>
      </Tooltip>
      )}
      {type === "edit" && variant === "iconbutton" && (
      <>
        {isList && (
        <MenuItem onClick={() => {
          removeAnchor();
          actions.edit(modelId);
        }}
        >
          <ListItemIcon size={buttonSize}>
            <Edit fontSize={fontSize} />
          </ListItemIcon>
          {t(tooltip)}
        </MenuItem>
        )}
        {!isList && (
        <Tooltip title={t(tooltip)} placement={placement} disableTouchListener>
          <IconButton
            size={buttonSize}
            onClick={() => {
              removeAnchor();
              actions.edit(modelId);
            }}
          >
            <Edit fontSize={fontSize} />
          </IconButton>
        </Tooltip>
        )}
      </>
      )}
      {type === "duplicate" && variant === "iconbutton" && (
      <>
        {isList && (
        <MenuItem onClick={() => {
          removeAnchor();
          actions.create(getModelData());
        }}
        >
          <ListItemIcon size={buttonSize}>
            <ContentCopy fontSize={fontSize} />
          </ListItemIcon>
          {t(tooltip)}
        </MenuItem>
        )}
        {!isList && (
        <Tooltip title={t(tooltip)} placement={placement} disableTouchListener>
          <IconButton
            size={buttonSize}
            onClick={() => {
              removeAnchor();
              actions.create(getModelData());
            }}
          >
            <ContentCopy fontSize={fontSize} />
          </IconButton>
        </Tooltip>
        )}
      </>
      )}
      {remove && (
      <>
        {isList && (
        <MenuItem onClick={() => {
          removeAnchor();
          actions.open(remove, modelId, childId);
        }}
        >
          <ListItemIcon size={buttonSize}>
            <DeleteOutline color="secondary" fontSize="small" />
          </ListItemIcon>
          {t("delete")}
        </MenuItem>
        )}
        {!isList && (
        <Tooltip title={t("delete")} placement={placement} disableTouchListener>
          <IconButton
            size={buttonSize}
            color="secondary"
            onClick={() => {
              removeAnchor();
              actions.open(remove, modelId, childId);
            }}
            style={style}
          >
            <DeleteOutline fontSize="inherit" />
          </IconButton>
        </Tooltip>
        )}
        <ConfirmRemoveFormDialog actions={actions} callback={callback} />
      </>
      )}

      {model === models.PLAN && (
        <PlanFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {model === models.ENTRY && (
        <EntryFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {model === models.SCORE && (
        <ScoreFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {model === models.STATISTIC && (
        <StatisticFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {(model === models.CUSTOMER) && (
        <CustomerFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {(model === models.SUBSCRIPTION) && (
        <SubscriptionFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {(model === models.MEDIA) && (
        <MediaFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {(model === models.NOTE) && (
        <NoteFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {(model === models.COMPETITOR) && (
        <CompetitorFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
      {(model === models.PLAN_SHARE) && (
        <PlanShareFormDialog
          actions={actions}
          callback={callback}
          options={options}
        />
      )}
    </>
  );
});

import React from "react";
import { useTranslation } from "react-i18next";

import { PreviewDialogTemplate } from "../../shared/templates/index.js";
import Panel from "./_panel.js";

export default React.memo(({ actions, plan }) => {
  const { t } = useTranslation();

  return (
    <PreviewDialogTemplate
      actions={actions}
      title={t("display_on_tv")}
      maxWidth="md"
      content={<Panel plan={plan} />}
    />
  );
});

import {
  Box, List, ListItem, ListItemText, Switch,
} from "@mui/material";
import React from "react";
import { useMutation } from "react-fetching-library";

import { apiAction } from "../../../../services/api/index.js";
import { planCustomerHidePut } from "../../../../services/api/plan.js";
import { BoxMessage, Loading } from "../../../shared/other/index.js";
import { PreviewDialogTemplate } from "../../../shared/templates/index.js";

export default React.memo(({ reloadPlans, actions = {}, plans = [] }) => {
  const { mutate, loading, status } = useMutation(apiAction);
  const dialogActions = {};

  actions.open = () => {
    dialogActions.open();
  };

  React.useEffect(() => {
    if (!loading && status === 200) {
      reloadPlans();
    }
  }, [status, loading]);

  return (
    <PreviewDialogTemplate
      actions={dialogActions}
      title="plans"
      maxWidth="xs"
      breakpointPixels={450}
      content={(
        <Box display="flex" flexDirection="column" width="100%" position="relative">
          {loading && <Loading />}
          {plans.length === 0 && <BoxMessage msg="no_plans_to_show" />}
          <List>
            {plans.map((plan, idx) => (
              <ListItem key={`plan_${idx}`}>
                <ListItemText id={plan.name.toLowerCase()} primary={plan.name} />
                <Switch
                  edge="end"
                  onChange={() => {
                    mutate(...planCustomerHidePut(plan._id));
                  }}
                  checked={!plan.customerHidden}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-wifi",
                  }}
                />

              </ListItem>
            ),

            )}
          </List>
        </Box>
      )}
    />
  );
});

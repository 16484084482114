import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import _ from "lodash";
import React from "react";

import { fixed, intervals } from "../../../../services/enums/index.js";
import { BgBox } from "../../../shared/boxes/index.js";
import { AvatarCircle } from "../../../shared/other/index.js";
import PlanBoxSwipe from "./PlanBoxSwipe/index.js";

export default React.memo(({ plan, reloadPlans }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  const getInitShift = () => {
    if (plan.interval === intervals.STATIC && !_.isNil(plan.latestScoreDate)) {
      return differenceInCalendarDays(new Date(plan.latestScoreDate), new Date(fixed.STATIC_BLOCK_START_TIME)) + 1;
    }

    return 0;
  };

  const getBg = () => {
    if (_.isNil(plan.bg)) {
      if (isXs && !_.isNil(plan.mobileBg)) {
        return plan.mobileBg;
      }

      return plan.user.bg;
    }

    return plan.bg;
  };

  return (
    <Box display="flex" flexWrap="wrap" width="100%" position="relative" marginBottom="40px">
      <BgBox bg={getBg()}>
        {!plan.competitionNameHidden && (
          <Typography
            variant={plan.name.length > 15 ? "h3" : "h2"}
            style={{
              color: "white",
              textShadow: "0px 0px 10px black",
              marginTop: "20px", marginLeft: "20px",
              fontWeight: 800,
              wordBreak: "break-word",
            }}
          >
            {plan.name}
          </Typography>
        )}
        {!plan.competitionNameHidden && (
          <Box display="flex">
            <Box flex="0 0" paddingLeft="20px">
              <AvatarCircle
                user={plan.user}
                style={{
                  width: 48, height: 48, fontSize: 24, border: `2px solid ${theme.palette.background.default}`,
                }}
              />
            </Box>
            <Box flex="1 0" display="flex" flexDirection="column" justifyContent="center" paddingLeft="5px">
              <Typography
                variant="h5"
                style={{ color: "white", textShadow: "0px 0px 10px black", fontWeight: 800 }}
              >
                {plan.user.name}
              </Typography>
            </Box>
          </Box>
        )}
      </BgBox>
      <Box
        display="flex"
        width="100%"
        flexWrap="wrap"
        marginTop="15px"
        position="relative"
        sx={{
          position: "relative",
        }}
      >
        <PlanBoxSwipe plan={plan} reloadPlans={reloadPlans} initShift={getInitShift()} />
      </Box>
    </Box>
  );
});

import _ from "lodash";
import React from "react";

import { customerDelete } from "../../../services/api/customer.js";
import { models } from "../../../services/enums/index.js";
import { createUserDisplayName } from "../../../services/utils/index.js";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other/index.js";
import { ModelItemTemplate } from "../../shared/templates/index.js";
import Body from "./_body.js";

export default React.memo(({
  item, highlight = [], callback, use,
}) => (
  <ModelItemTemplate
    inModal={_.isFunction(use)}
    header={<ItemHeaderWithHighlight name={createUserDisplayName(item.customer)} highlight={highlight} />}
    body={<Body item={item} highlight={highlight} />}
    footer={<ItemFooter item={item} callback={callback} use={use} model={models.CUSTOMER} modelDelete={customerDelete} />}
  />
));

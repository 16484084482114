import { Notifications } from "@mui/icons-material";
import {
  Badge, IconButton, styled, Tooltip,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { notificationCheckGet } from "../../../services/api/notification.js";
import { NotificationPreviewDialog } from "../index.js";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: "\"\"",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default memo(() => {
  const { t } = useTranslation();
  const { payload, query } = useQuery(notificationCheckGet());
  const [newer, setNewer] = useState(false);
  const [older, setOlder] = useState(false);

  const actions = {};

  actions.onClose = () => {
    query();
  };

  useEffect(() => {
    setNewer(payload?.newer === true);
    setOlder(payload?.older === true);
  }, [payload]);

  useEffect(() => {
    const timeout = setTimeout(query, 300000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Tooltip title={t("notifications")} placement="bottom" disableTouchListener>
        <IconButton
          size="large"
          onClick={() => {
            setNewer(false);
            actions.open();
          }}
        >
          {newer && (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Notifications />
          </StyledBadge>
          )}
          {!newer && <Notifications />}
        </IconButton>
      </Tooltip>
      <NotificationPreviewDialog actions={actions} older={older} />
    </>
  );
});

import { methods } from "../enums/index.js";
import { apiAction } from "./index.js";

export const mediaDataPost = data => ["/media", methods.POST, data];
export const mediaDataPut = data => [`/media/${data._id}`, methods.PUT, data];
export const mediaDelete = id => [`/media/${id}`, methods.DELETE];
export const mediaOneGet = id => apiAction(`/media/one/${id}`);
export const mediaFilterGet = (search = "", page = 1, limit = 100, sort = "-createdAt", filter = "") => apiAction(
  `/media/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);

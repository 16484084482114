import React, { useState } from "react";

import { PreviewDialogTemplate } from "../../shared/templates/index.js";
import MessagePanel from "../MessagePanel/index.js";

export default ({ actions }) => {
  const dialogActions = {};

  const [entryId, setEntryId] = useState(null);
  const [subordinateId, setSubordinateId] = useState(null);
  const [userName, setUserName] = useState("");

  actions.open = (entryId, subordinateId, userName) => {
    setEntryId(entryId);
    setSubordinateId(subordinateId);
    setUserName(userName || "");
    dialogActions.open();
  };

  return (
    <PreviewDialogTemplate
      actions={dialogActions}
      title="messages_with_x"
      titleOptions={{ user: userName }}
      maxWidth="xs"
      breakpointPixels={450}
      content={(entryId && <MessagePanel entryId={entryId} subordinateId={subordinateId} />)}
    />
  );
};

import _ from "lodash";
import * as yup from "yup";

import { units } from "../../../services/enums/index.js";

export default t => yup.object().shape({
  entryId: yup.string().trim()
    .required(t("field_is_required")),
  customerUserId: yup.mixed(),
  scoreUnit: yup.string().trim()
    .required(t("field_is_required")),
  videoLink: yup.string().trim()
    .nullable(),
  valueA: yup.string().trim()
    .when("isTimeCap", (isTimeCap, schema) => (isTimeCap ? schema : schema.required(t("field_is_required")))),
  valueB: yup.string().trim()
    .when(["scoreUnit", "isTimeCap"], (scoreUnit, isTimeCap, schema) => (_.isEqual(scoreUnit, units.TIME) && !isTimeCap ? schema.required(t("field_is_required")) : schema)),
  valueC: yup.string().trim()
    .when(["scoreUnit", "isTimeCap"], (scoreUnit, isTimeCap, schema) => (_.isEqual(scoreUnit, units.TIME) && !isTimeCap ? schema.required(t("field_is_required")) : schema)),
  public: yup.boolean().required(t("field_is_required")),
  isConfirmed: yup.boolean().required(t("field_is_required")),
  isTieBreak: yup.boolean().nullable(),
  isTimeCap: yup.boolean().nullable(),
  timeCapReps: yup.number().nullable(),
});

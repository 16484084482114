import _ from "lodash";

import { createUserDisplayName } from "../../../services/utils/index.js";

export default item => {
  if (_.isNil(item.sender)) {
    return "Indefit";
  }

  return createUserDisplayName(item.sender);
};

import { DeleteOutline, Search } from "@mui/icons-material";
import {
  Chip, IconButton, Tooltip, Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { mediaDelete } from "../../../services/api/media.js";
import { models } from "../../../services/enums/index.js";
import { safeUpperCase } from "../../../services/utils/index.js";
import { ItemFooter } from "../../shared/other/index.js";
import { ModelItemTemplate } from "../../shared/templates/index.js";
import MediaPreviewDialog from "../MediaPreviewDialog/index.js";

export default React.memo(({ item, remove, coach }) => {
  const actions = {};
  const { t } = useTranslation();

  return (
    <ModelItemTemplate
      body={(
        <Typography variant="body1" style={{ width: "100%", fontWeight: 600 }}>
          {item.name}
        </Typography>
      )}
      footer={(
        <ItemFooter
          showEdit={false}
          showRemove={false}
          item={item}
          buttons={(
            <>
              <Tooltip title={t("preview")} disableTouchListener>
                <IconButton size="small" onClick={() => actions.open(item)}>
                  <Search fontSize="inherit" />
                </IconButton>
              </Tooltip>
              {coach && (
              <Tooltip title={t("delete")} disableTouchListener>
                <IconButton size="small" color="secondary" onClick={() => remove(item._id)}>
                  <DeleteOutline fontSize="inherit" />
                </IconButton>
              </Tooltip>
              )}
              <MediaPreviewDialog actions={actions} />
            </>
          )}
          modelDelete={mediaDelete}
          model={models.ENTRY}
          chip={coach && <Chip padding="0" color="secondary" size="small" label={safeUpperCase(item.file.mime)} />}
        />
      )}
    />
  );
});

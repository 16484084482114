import _ from "lodash";

import { createFuncDef } from "../../../services/utils/index.js";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      email: _.isNil(data.competitor) ? "" : data.competitor.email,
      competitionCategory: _.isNil(data.competitionCategory) ? null : data.competitionCategory,
      planId: data.planId,
    });
  }),
];

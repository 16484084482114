import { Flip, GridOn, PhotoCamera } from "@mui/icons-material";
import {
  Box, FormLabel, IconButton, Input, Slider, Tooltip,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";

import { getCroppedImg } from "../../../../services/utils/index.js";

export default ({
  actions, imgWidth, imgHeight, cropperHeight, cropShape,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    image: null,
    crop: { x: 0, y: 0 },
    rotation: 0,
    flip: { horizontal: false, vertical: false },
    zoom: 1,
    minZoom: 1,
    maxZoom: 5,
    zoomStep: 0.02,
    cropped: null,
  });

  actions.getImage = async () => {
    if (_.isNil(state.cropped) || _.isNil(state.image)) {
      enqueueSnackbar(t("no_data_to_save"), { variant: "warning" });

      return null;
    }
    const base64 = await getCroppedImg(state.image, {
      ...state.cropped,
      rotation: state.rotation,
      flipHorizontally: state.flip.horizontal,
      flipVertically: state.flip.vertical,
      scaleWidth: imgWidth,
      scaleHeight: imgHeight,
      type: cropShape,
    });
    const spl = base64.split(",");

    if (spl.length < 2) {
      enqueueSnackbar(t("invalid_data"), { variant: "warning" });

      return null;
    }

    return spl[1];
  };

  const handleCapture = event => {
    if (_.isNil(event.target.files[0])) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);
    fileReader.onload = e => {
      setState({ ...state, image: e.target.result });
    };
  };

  const onCropChange = crop => {
    setState({ ...state, crop });
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setState({
      ...state,
      cropped: croppedAreaPixels,
    });
  };

  const onZoomChange = zoom => {
    setState({ ...state, zoom });
  };

  return (

    <Box display="flex" flexWrap="wrap" width="100%">
      <Box style={{
        position: "relative", width: "100%", minWidth: imgWidth + 10, height: cropperHeight, backgroundColor: "#777c7f",
      }}
      >
        <Box style={{
          position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
        }}
        >
          <Cropper
            image={state.image}
            crop={state.crop}
            zoom={state.zoom}
            rotation={state.rotation}
            cropShape={cropShape}
            showGrid
            zoomSpeed={1}
            minZoom={state.minZoom}
            maxZoom={state.maxZoom}
            cropSize={{ width: imgWidth, height: imgHeight }}
            restrictPosition={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            transform={[
              `translate(${state.crop.x}px, ${state.crop.y}px)`,
              `rotateZ(${state.rotation}deg)`,
              `rotateY(${state.flip.horizontal ? 180 : 0}deg)`,
              `rotateX(${state.flip.vertical ? 180 : 0}deg)`,
              `scale(${state.zoom})`,
            ].join(" ")}
          />
        </Box>
      </Box>
      <Box display="flex" style={{ marginTop: 5 }} flexWrap="wrap" width="100%">
        <Box flex="0">
          <Input
            accept="image/*"
            style={{ display: "none" }}
            id="avatar"
            onChange={handleCapture}
            type="file"
          />
          <FormLabel htmlFor="avatar">
            <Tooltip title={t("select_file")} placement="bottom" disableTouchListener>

              <IconButton color="primary" component="span">
                <PhotoCamera />
              </IconButton>
            </Tooltip>
          </FormLabel>
        </Box>
        <Box flex="0">
          <Tooltip title={t("flip_horizontal")} placement="bottom" disableTouchListener>

            <IconButton
              color="default"
              component="span"
              onClick={() => setState({
                ...state,
                flip: {
                  ...state.flip,
                  horizontal: !state.flip.horizontal,
                },
              })}
            >
              <Flip />
            </IconButton>
          </Tooltip>
        </Box>
        <Box flex="0">
          <Tooltip title={t("flip_vertical")} placement="bottom" disableTouchListener>

            <IconButton
              color="default"
              component="span"
              onClick={() => setState({
                ...state,
                flip: {
                  ...state.flip,
                  vertical: !state.flip.vertical,
                },
              })}
            >
              <Flip style={{ transform: "rotate(90deg)" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box flex="0">
          <Tooltip title={t("grid")} placement="bottom" disableTouchListener>
            <IconButton
              color="default"
              component="span"
              onClick={() => setState({
                ...state,
                showGrid: !state.showGrid,
              })}
            >
              <GridOn />
            </IconButton>
          </Tooltip>
        </Box>
        <Box flex="0 0 100px" display="flex" alignItems="center">
          <Tooltip title={t("zoom")} placement="bottom" disableTouchListener>
            <Slider
              value={state.zoom}
              min={state.minZoom}
              max={state.maxZoom}
              step={state.zoomStep}
              onChange={(e, newVal) => setState({ ...state, zoom: newVal })}
              style={{ marginLeft: 5, marginRight: 10 }}
            />
          </Tooltip>
        </Box>
        <Box flex="0 0 100px" display="flex" alignItems="center">
          <Tooltip title={t("rotation")} placement="bottom" disableTouchListener>
            <Slider
              value={state.rotation}
              min={0}
              max={360}
              onChange={(e, newVal) => setState({ ...state, rotation: newVal })}
              style={{ marginLeft: 5, marginRight: 10 }}
            />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

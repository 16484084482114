import React from "react";

import { localStorageDict } from "../enums/index.js";
import { useLocalStorage } from "./index.js";

/**
 * Provides a function to check role of the currently logged user
 * @returns {Object} { isRole }
 */
export default () => {
  const [role] = useLocalStorage(localStorageDict.ROLE, null);
  const isRole = React.useCallback(roleToValidate => roleToValidate === role, [role]);

  return { isRole };
};

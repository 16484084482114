import React from "react";
import { useTranslation } from "react-i18next";

import { subscriptionDataPost, subscriptionDataPut, subscriptionOneGet } from "../../../services/api/subscription.js";
import { useFormEngine } from "../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../services/utils/index.js";
import { CustomerField, DateTimeField, PlanField } from "../../shared/fields/index.js";
import { FormDialogTemplate } from "../../shared/templates/index.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

export default React.memo(({ actions, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, subscriptionDataPost, subscriptionDataPut, subscriptionOneGet, {
    _id: null,
    planId: "",
    planName: "",
    customerId: "",
    customerName: "",
    start: new Date(),
    end: new Date(),
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="xs"
      isOpen={isOpen}
      onClose={handleClose}
      title="subscription"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <PlanField
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValues={setFieldValues}
            options={{ showCompetitions: false }}
          />
          <CustomerField
            form={form}
            setFieldValues={setFieldValues}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
          />
          <DateTimeField
            fieldName="start"
            label="start"
            form={form}
            setFieldValue={setFieldValue}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
          />
          <DateTimeField
            fieldName="end"
            label="end"
            form={form}
            setFieldValue={setFieldValue}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
          />
        </>
)}
    />
  );
});

import { OpenInNew } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import React from "react";

export default ({
  children, style, variant, color, fontWeight, labels = [],
}) => {
  const regEx = /(https?:\/\/[^\s]+)/g;
  const elements = children.split(regEx);

  return (
    <Typography style={{ wordBreak: "break-word", display: "inline", ...style }} variant={variant} color={color} fontWeight={fontWeight}>
      {elements.map((item, idx) => (item.match(regEx)
        ? (
          <Link key={`link_${idx}`} href={item} underline="hover" target="_blank" rel="noopener noreferrer" sx={{ display: "inherit", alignItems: "center" }}>
            {labels[idx - 1] ?? item}
            &nbsp;
            <OpenInNew fontSize="inherit" />
          </Link>
        ) : item))}
    </Typography>
  );
};

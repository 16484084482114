import { OpenInNew, PhoneAndroid, PhotoCamera } from "@mui/icons-material";
import {
  Alert, Box, Button, IconButton, Tooltip,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { fileBlobGet } from "../../../services/api/file.js";
import {
  planBgDataPut, planDataPost, planDataPut, planMobileBgDataPut, planOneGet,
} from "../../../services/api/plan.js";
import { featureFlags, intervals, periods } from "../../../services/enums/index.js";
import { useFeatureFlag, useFormEngine } from "../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../services/utils/index.js";
import { FinancePlanFormFields } from "../../finance/index.js";
import { BgBox } from "../../shared/boxes/index.js";
import { FileSelectButton } from "../../shared/buttons/index.js";
import { BackgroundImageFormDialog } from "../../shared/dialogs/index.js";
import {
  CheckboxField, MultilineTextField, NumberField, PeriodSelectField, PlanTypeSelectField, TagField, TextField,
} from "../../shared/fields/index.js";
import { FormDialogTemplate } from "../../shared/templates/index.js";
import beforeSubmitFunc from "./_before-submit-func.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

export default React.memo(({ actions, callback }) => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatureFlag(featureFlags.PAYMENTS);
  const bgActions = {};
  const mobileBgActions = {};

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage, isEditing,
  } = useFormEngine(actions, planDataPost, planDataPut, planOneGet, {
    _id: null,
    name: "",
    order: 1,
    interval: "",
    shift: 0,
    scoreShift: 0,
    hidden: false,
    competitionNameHidden: false,
    hiddenDays: false,
    periodName: periods.DAY,
    competitionDesc: "",
    competitionCategories: [],
    data: "",
    mime: "",
    terms: null,
    price: 0,
    inShop: false,
    shopDesc: "",
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks, [], beforeSubmitFunc);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="plan"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      titleButtons={isEditing && (
      <>
        <Tooltip title={t("add_background")} placement="bottom" disableTouchListener>
          <IconButton color="primary" component="span" onClick={() => bgActions.open()}>
            <PhotoCamera />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("add_mobile_background")} placement="bottom" disableTouchListener>
          <IconButton color="primary" component="span" onClick={() => mobileBgActions.open()}>
            <PhoneAndroid />
          </IconButton>
        </Tooltip>
        <BackgroundImageFormDialog id={form._id} route={planBgDataPut} actions={bgActions} callback={handleClose} />
        <BackgroundImageFormDialog id={form._id} route={planMobileBgDataPut} actions={mobileBgActions} callback={handleClose} width={400} />
      </>
      )}
      body={(
        <>
          {form.bg && <BgBox bg={form.bg} />}
          {form.bg && <Box height="20px" />}
          {form.mobileBg && <BgBox bg={form.mobileBg} width={400} />}
          {form.mobileBg && <Box height="20px" />}
          <TextField
            label="name"
            fieldName="name"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          {_.isEqual(form.interval, intervals.COMPETITION) && (
            <CheckboxField
              label="competition_name_hidden"
              fieldName="competitionNameHidden"
              helper="competition_name_hidden_desc"
              form={form}
              setFieldValue={setFieldValue}
            />
          )}
          <PlanTypeSelectField
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
            disabled={isEditing}
          />
          {_.isEqual(form.interval, intervals.COMPETITION) && (
            <>
              <MultilineTextField
                minRows={2}
                maxRows={20}
                label="description"
                fieldName="competitionDesc"
                form={form}
                isFieldError={isFieldError}
                fieldErrorMessage={fieldErrorMessage}
                setFieldValue={setFieldValue}
              />
              <Alert severity="info" style={{ marginBottom: 20 }}><Trans i18nKey="text_alert_info">test</Trans></Alert>
              <TagField
                label="categories"
                fieldName="competitionCategories"
                form={form}
                isFieldError={isFieldError}
                fieldErrorMessage={fieldErrorMessage}
                setFieldValue={setFieldValue}
              />
              <FileSelectButton
                label="add_terms_and_condition"
                accept="application/pdf"
                setFieldValue={setFieldValue}
                setFieldValues={setFieldValues}
                iconPdf
                iconCamera={false}
                type="pdf"
                helperText={t("terms_and_conditions_have_to_be_in_pdf")}
              />
              {form.terms && (
              <Link to={{ pathname: fileBlobGet(form.terms) }} target="_blank">
                <Button style={{ marginBottom: "10px" }}>
                  {t("open_current_terms")}
                  &nbsp;
                  <OpenInNew fontSize="inherit" />
                </Button>
              </Link>
              )}
              <Alert severity="info" style={{ marginBottom: 20 }}>
                {t("competition_plan_desc")}
              </Alert>
            </>
          )}
          {isEditing && (
            <NumberField
              fieldName="order"
              label="order"
              min={1}
              max={100}
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
          )}
          {_.isEqual(form.interval, intervals.COMPETITION) && (
            <CheckboxField
              label="hide_days"
              fieldName="hiddenDays"
              helper="hide_days_desc"
              form={form}
              setFieldValue={setFieldValue}
            />
          )}
          {_.includes([intervals.STATIC, intervals.COMPETITION], form.interval) && (
            <PeriodSelectField
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
          )}
          {!_.includes([intervals.STATIC, intervals.COMPETITION], form.interval) && (
            <>
              <NumberField
                fieldName="shift"
                label="workouts_visibility"
                helper="shift_description"
                form={form}
                isFieldError={isFieldError}
                fieldErrorMessage={fieldErrorMessage}
                setFieldValue={setFieldValue}
              />
              <NumberField
                fieldName="scoreShift"
                label="score_visibility"
                helper="score_visibility_desc"
                form={form}
                isFieldError={isFieldError}
                fieldErrorMessage={fieldErrorMessage}
                setFieldValue={setFieldValue}
              />
            </>
          )}
          <CheckboxField
            label="hide_plan"
            fieldName="hidden"
            helper="hide_plan_info"
            form={form}
            setFieldValue={setFieldValue}
          />

          {isEnabled() && (
            <FinancePlanFormFields
              fieldName="price"
              label="price"
              helper="price_field_desc"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
          )}
        </>
      )}
    />
  );
});

import { Clear, ViewList } from "@mui/icons-material";
import {
  FormHelperText, IconButton, InputAdornment, TextField, Tooltip,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { models } from "../../../../services/enums/index.js";
import { useFullScreen } from "../../../../services/hooks/index.js";
import { CustomersPanel } from "../../../customer/index.js";
import { PlansPanel } from "../../../plan/index.js";
import { StatisticPanel } from "../../../statistic/index.js";
import { SelectDialogTemplate } from "../../templates/index.js";

export default ({
  value, label, variant, style, model, error, helperText, use, clear, options = {},
}) => {
  const actions = {};
  const { t } = useTranslation();
  const { fullScreen } = useFullScreen();

  return (
    <>
      {fullScreen ? (
        <TextField
          variant={variant}
          style={style}
          label={t(label)}
          value={value}
          onClick={() => actions.open()}
          onChange={() => ({})}
          InputProps={{
            endAdornment:
          <InputAdornment position="end">
            {clear && (
              <Tooltip placement="top" title={t("clear")} disableTouchListener>
                <IconButton onClick={() => clear()} edge="end">
                  <Clear />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>,
          }}
          error={error}
        />
      ) : (
        <TextField
          variant={variant}
          style={style}
          label={t(label)}
          value={value}
          onChange={() => ({})}
          InputProps={{
            endAdornment:
          <InputAdornment position="end">
            {clear && (
              <Tooltip placement="top" title={t("clear")} disableTouchListener>
                <IconButton onClick={() => clear()} edge="end">
                  <Clear />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip placement="top" title={t("select")} disableTouchListener>
              <IconButton onClick={() => actions.open()} edge="end">
                <ViewList />
              </IconButton>
            </Tooltip>
          </InputAdornment>,
          }}
          error={error}
        />
      )}
      <FormHelperText error={error}>{helperText}</FormHelperText>
      {model === models.PLAN && <SelectDialogTemplate title="select_plan" modelPanel={PlansPanel} actions={actions} use={use} options={options} />}
      {model === models.CUSTOMER && <SelectDialogTemplate title="select_customer" modelPanel={CustomersPanel} actions={actions} use={use} options={options} />}
      {model === models.STATISTIC && <SelectDialogTemplate title="select_statistic" modelPanel={StatisticPanel} actions={actions} use={use} options={options} />}
    </>
  );
};

import { Share, VisibilityOff } from "@mui/icons-material";
import { Chip, IconButton, Tooltip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { planDelete } from "../../../services/api/plan.js";
import { models } from "../../../services/enums/index.js";
import { formatPrice, safeUpperCase } from "../../../services/utils/index.js";
import { PlanSharePanel } from "../../plan-share/index.js";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other/index.js";
import { ModelItemTemplate, SelectDialogTemplate } from "../../shared/templates/index.js";

export default ({
  item, highlight = [], callback, use,
}) => {
  const actionsPlanShares = {};
  const { t } = useTranslation();

  return (
    <ModelItemTemplate
      inModal={_.isFunction(use)}
      header={<ItemHeaderWithHighlight name={item.name} highlight={highlight} />}
      footer={(
        <ItemFooter
          item={item}
          callback={callback}
          use={use}
          model={models.PLAN}
          modelDelete={planDelete}
          chip={(!_.isNil(item.user.stripe) && item.price > 0 && <Chip padding="0" color="success" size="small" variant="outlined" label={`${formatPrice(item.price)} ${safeUpperCase(item.user.stripe.currency)}`} />)}
          buttons={(
            <>
              {!_.isNil(item.hidden) && item.hidden && (
              <Tooltip title={t("hidden_on_main_panel")} placement="bottom" disableTouchListener>
                <VisibilityOff size="small" color="disabled" />
              </Tooltip>
              )}
              <Tooltip title={t("share")} placement="bottom" disableTouchListener>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    actionsPlanShares.open({ plan: { _id: item._id, name: item.name } });
                  }}
                >
                  <Share fontSize="small" />
                </IconButton>
              </Tooltip>
              <SelectDialogTemplate
                title="shares_for_plan"
                titleExt={item.name}
                modelPanel={PlanSharePanel}
                actions={actionsPlanShares}
                maxWidth="lg"
                initData={{ planId: item._id, planName: item.name }}
              />
            </>
          )}
        />
      )}
    />
  );
};

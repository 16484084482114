import {
  Box, Divider, Paper, Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { ScoreEntryPreviewPanel } from "../../../../score/index.js";

export default React.memo(({
  actions, entry, plan, shift,
}) => {
  const { t } = useTranslation();

  return (
    <Paper style={{
      marginBottom: "20px", paddingTop: 8, position: "relative", flexGrow: 1,
    }}
    >
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Typography variant="h3" style={{ fontWeight: 900, paddingTop: 8 }}>{t("scores")}</Typography>
        <Divider variant="middle" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />
        <ScoreEntryPreviewPanel actions={actions} entry={entry} plan={plan} shift={shift} canEdit={false} />
      </Box>
    </Paper>
  );
});

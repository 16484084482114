import React from "react";
import { useTranslation } from "react-i18next";

import { planShareFilterGet } from "../../../services/api/plan-share.js";
import { models } from "../../../services/enums/index.js";
import { DataPanelTemplate } from "../../shared/templates/index.js";
import { PlanSharePanelItem } from "../index.js";

export default React.memo(({ bgFrom, initData }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      filterGet={planShareFilterGet}
      dataPrefix="planShares"
      addTooltip="add_user"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      filterDescs={{
        plan: { type: "hidden", value: initData.planId },
      }}
      model={models.PLAN_SHARE}
      panelItem={PlanSharePanelItem}
      bgFrom={bgFrom}
      initData={initData}
    />
  );
});

import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { userStripeLoginLinkGet } from "../../../services/api/user.js";

export default React.memo(() => {
  const { t } = useTranslation();
  const {
    loading, payload, query,
  } = useQuery(userStripeLoginLinkGet(), false);

  useEffect(() => {
    if (!loading && !_.isNil(payload)) {
      window.location.href = payload.loginLink;
    }
  }, [payload, loading]);

  return (
    <LoadingButton variant="contained" loading={loading} onClick={() => query()}>{t("go_to_my_stripe_account")}</LoadingButton>
  );
});

export { default as base64MimeType } from "./base-64-mime-type.js";
export { default as confirm } from "./confirm.js";
export { default as confirmOption } from "./confirm-option.js";
export { default as createFuncDef } from "./create-func-def.js";
export { default as createKVObj } from "./create-kv-obj.js";
export { default as createUserDisplayName } from "./create-user-display-name.js";
export { default as dateToShortText } from "./date-to-short-text.js";
export { default as deepen } from "./deepen.js";
export { default as formatPrice } from "./format-price.js";
export { default as funcCaller } from "./func-caller.js";
export { default as getCroppedImg } from "./get-cropped-img.js";
export { default as getDefault } from "./get-default.js";
export { default as getLocale } from "./get-locale.js";
export { default as imageOrVideo } from "./image-or-video.js";
export { default as isInt } from "./is-int.js";
export { default as logError } from "./log-error.js";
export { default as normalizeHost } from "./normalize-host.js";
export { default as numberFormatWithMaxDigits } from "./number-format-with-max-digits.js";
export { default as onSuccessCallbacks } from "./on-success-callbacks.js";
export { default as safeUpperCase } from "./safe-uppercase.js";
export { default as selectUnit } from "./select-unit.js";
export { default as setDateByInterval } from "./set-date-by-interval.js";
export { default as strPadLeft } from "./str-pad-left.js";
export { default as stringFormatWithSpaces } from "./string-format-with-spaces.js";
export { default as timeStringFormat } from "./time-string-format.js";
export { default as wordCut } from "./word-cut.js";
export { default as writeStorage } from "./write-storage.js";

export const marginBottomForFields = () => 15;

/* eslint-disable camelcase */
import { createFuncDef, getDefault } from "../../../../services/utils/index.js";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      card__age: getDefault(data.card, "age", ""),
      card__weight: getDefault(data.card, "weight", ""),
      card__height: getDefault(data.card, "height", ""),
      card__club: getDefault(data.card, "club", ""),
      card__exp: getDefault(data.card, "exp", ""),
    });
  }),
];

import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { competitorDataPost, competitorDataPut, competitorOneGet } from "../../../services/api/competitor.js";
import { useFormEngine } from "../../../services/hooks/index.js";
import { createFuncDef, onSuccessCallbacks } from "../../../services/utils/index.js";
import { CompetitionCategorySelectField, TextField } from "../../shared/fields/index.js";
import { FormDialogTemplate } from "../../shared/templates/index.js";
import AddCustomerButton from "./_add-customer-button.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

export default React.memo(({ actions = {}, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, isEditing, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, competitorDataPost, competitorDataPut, competitorOneGet, {
    email: "",
    competitionCategory: null,
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks, [], null, [createFuncDef(() => setFieldValue("email", ""))]);
    // setFieldValue("email", "") above is required, because categories field is using setFieldValue to select automatically the first element and
    // it causes a modification of email field in this case. Eresing explicitly an email field on modal open solves the proble.

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="competitor"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      titleButtons={!isEditing && <AddCustomerButton setFieldValue={setFieldValue} />}
      body={(
        <>
          <TextField
            disabled={isEditing}
            label="email"
            fieldName="email"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <CompetitionCategorySelectField
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
            setFieldValues={setFieldValues}
            isEditing={isEditing}
          />
        </>
)}
      comment={!isEditing && (
        <Alert severity="info">
          {t("add_competitor_comment")}
        </Alert>
      )}
    />
  );
});

import { Equalizer, VisibilityOff } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { entryDelete } from "../../../services/api/entry.js";
import { models } from "../../../services/enums/index.js";
import { useCommonDateFormats } from "../../../services/hooks/index.js";
import { safeUpperCase } from "../../../services/utils/index.js";
// import { NoteEntryPreviewDialog } from "../../note";
import { ScoreEntryPreviewDialog } from "../../score/index.js";
import { ModelActionButton, PreviewButton } from "../../shared/buttons/index.js";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other/index.js";
import { ModelItemTemplate } from "../../shared/templates/index.js";
import Body from "./_body.js";

export default React.memo(({
  item, highlight = [], callback, use,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useCommonDateFormats();

  // const planIsNotCompetition = () => item.plan.interval !== intervals.COMPETITION;

  return (
    <ModelItemTemplate
      inModal={_.isFunction(use)}
      header={<ItemHeaderWithHighlight name={formatDate(item.date)} fontWeight={100} fontVariant="caption" />}
      body={<Body item={item} highlight={highlight} />}
      footer={(
        <ItemFooter
          item={item}
          callback={callback}
          use={use}
          buttons={(
            <>
              {!_.isNil(item.isHidden) && item.isHidden && (
              <Tooltip title={t("entry_visible_only_for_you")} placement="bottom" disableTouchListener>
                <VisibilityOff size="small" color="disabled" />
              </Tooltip>
              )}
              <PreviewButton
                args={[item, item.plan]}
                previewDialog={ScoreEntryPreviewDialog}
                icon={Equalizer}
                tooltipTitle="scores"
              />
              {/* {planIsNotCompetition() && (
              <PreviewButton
                args={[item._id]}
                previewDialog={NoteEntryPreviewDialog}
                icon={NoteIcon}
                tooltipTitle="notes"
              />
              )} */}
              <ModelActionButton
                type="duplicate"
                variant="iconbutton"
                model={models.ENTRY}
                tooltip="create_duplicate"
                modelData={item}
                fontSize="inherit"
                buttonSize="small"
                callback={callback}
              />
            </>
          )}
          modelDelete={entryDelete}
          model={models.ENTRY}
          chip={(
            <>
              <Chip padding="0" color="primary" size="small" label={safeUpperCase(item.plan.name)} />
              {!_.isNil(item.competitionCategory) && <Chip padding="0" color="secondary" size="small" label={safeUpperCase(item.competitionCategory)} />}
            </>
          )}
        />
      )}
    />
  );
});

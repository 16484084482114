import {
  Box, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme,
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { scoreFilterByStatisticGet } from "../../../services/api/score.js";
import { units } from "../../../services/enums/index.js";
import { useCommonDateFormats, useOpenCloseControl } from "../../../services/hooks/index.js";
import { createUserDisplayName, strPadLeft } from "../../../services/utils/index.js";
import {
  BoxMessage, FormDialogActions, GenericError, Loading,
} from "../../shared/other/index.js";

export default ({ actions }) => {
  const dialogContentRef = React.useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { isOpen, handleOpen, handleClose } = useOpenCloseControl();
  const {
    loading, query, error, status,
  } = useParameterizedQuery(scoreFilterByStatisticGet, false);
  const [statisticName, setStatisticName] = React.useState("");
  const [tableHeader, setTableHeader] = React.useState(null);
  const [tableRows, setTableRows] = React.useState(null);
  const [xAxisData, setXAxisData] = React.useState(null);
  const [series, setSeries] = React.useState(null);
  const [unit, setUnit] = React.useState(null);
  const { formatDate } = useCommonDateFormats();

  const getValue = (valA, valB, valC, unit) => {
    switch (unit) {
      case units.WEIGHT:
        return parseFloat(valA);
      case units.REPS:
        return parseInt(valA, 10);
      case units.DISTANCE:
        return parseFloat(valA);
      case units.TIME: {
        return (parseInt(valA, 10) * 3600) + (parseInt(valB, 10) * 60) + parseInt(valC, 10);
      }
      case units.ROUNDS: {
        return parseInt(valA, 10);
      }
      default:
        return 0;
    }
  };

  const formatByUnit = (val, unit) => {
    switch (unit) {
      case units.TIME: {
        const hours = Math.floor(val / 3600);
        let rSec = val % 3600;
        const minutes = Math.floor(rSec / 60);
        rSec %= 60;

        return `${strPadLeft(hours, "0", 2)}:${strPadLeft(minutes, "0", 2)}:${strPadLeft(rSec, "0", 2)}`;
      }
      default:
        return val;
    }
  };

  actions.open = statisticId => {
    handleOpen();
    query(statisticId).then(res => {
      if (_.isNil(res.payload) || _.isNil(res.payload.entries) || _.isEmpty(res.payload.entries)) {
        return;
      }
      setStatisticName(res.payload.statistic[0].name);
      const entries = res.payload.entries;
      setUnit(entries[0].scoreUnit);

      // CHART ---------------------------------

      setXAxisData(_(entries).map(entry => new Date(entry.scores[0]?.createdAt ?? entry.date)).value());
      const series = [];
      let entryIdx = 0;

      entries.forEach(entry => {
        entry.scores.forEach(score => {
          const scoreValue = getValue(score.valueA, score.valueB, score.valueC, entry.scoreUnit);
          let present = false;
          series.forEach(item => {
            if (item.id === score.user._id) {
              item.data[entryIdx] = scoreValue;
              present = true;
            }
          });

          if (!present) {
            const data = _.times(entries.length, _.constant(null));
            data[entryIdx] = scoreValue;
            series.push({
              data,
              label: createUserDisplayName(score.user),
              id: score.user._id,
              connectNulls: true,
              valueFormatter: val => formatByUnit(val, entries[0].scoreUnit),
            });
          }
        });
        entryIdx += 1;
      });
      setSeries(series);

      // TABLE ---------------------------------

      setTableHeader(_(entries).map(entry => ({
        date: formatDate(entry.scores[0]?.createdAt ?? entry.date),
        title: entry.title,
        content: entry.content,
      }))
        .value());

      const rows = {};

      _.map(entries, entry1 => {
        _.map(entry1.scores, score => {
          if (_.isNil(rows[score.user._id])) {
            rows[score.user._id] = _.concat([createUserDisplayName(score.user)], _(entries).map(entry2 => {
              let res = "-";
              _.map(entry2.scores, score1 => {
                if (score1.user._id === score.user._id) {
                  res = formatByUnit(getValue(score1.valueA, score1.valueB, score1.valueC, score1.scoreUnit), score1.scoreUnit);
                }
              });

              return res;
            })
              .value());
          }
        });
      });
      setTableRows(_(rows).orderBy(item => item[0], ["asc"])
        .map(item => item)
        .value());
    });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={isOpen()}
      onClose={handleClose}
    >
      <DialogTitle>
        {t("scores")}
        {" "}
        {statisticName}
      </DialogTitle>
      <DialogContent ref={dialogContentRef}>
        {loading && <Loading />}
        {error && <GenericError status={status} />}
        {_.isNil(tableRows) && <BoxMessage msg={t("no_scores")} />}
        <Box display="flex" justifyContent="center" width="100%">

          <TableContainer component={Paper} style={{ maxHeight: 300, marginBottom: 30 }}>
            <Table size="small" aria-label="a dense table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {_.map(tableHeader, (item, i) => <TableCell key={`h_${i}`} align="right">{item.date}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(tableRows, (cells, i) => (
                  <TableRow key={`row_${i}`}>
                    {_.map(cells, (cell, j) => (
                      <TableCell key={`cell_${i}${j}`} align={j > 0 ? "right" : "left"}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
        {!_.isNil(xAxisData) && !_.isNil(series) && !_.isEqual(units.DESCRIPTION, unit) && (
        <Box display="flex" justifyContent="center" width="100%" overflow="scroll">
          <Box display="flex" justifyContent="center" width="100%" minWidth={300}>
            <LineChart
              yAxis={[
                { valueFormatter: val => formatByUnit(val, unit) },
              ]}
              xAxis={[
                {
                  scaleType: "time",
                  data: xAxisData,
                },
              ]}
              series={series}
              height={400}
            />
          </Box>
        </Box>
        )}
      </DialogContent>
      <FormDialogActions close={handleClose} />
    </Dialog>
  );
};

import _ from "lodash";

import { units } from "../../../services/enums/index.js";
import { timeStringFormat } from "../../../services/utils/index.js";

export default (item, scoreUnit) => {
  switch (scoreUnit) {
    case units.TIME: return !_.isNil(item.isTimeCap) && item.isTimeCap ? `CAP+${item.timeCapReps}` : timeStringFormat(item.valueA, item.valueB, item.valueC);
    default: return item.valueA;
  }
};

import { normalizeHost } from "./utils/index.js";

export const plansUrl = () => "/plans";
export const entriesUrl = () => "/entries";
export const statisticsUrl = () => "/statistics";
export const customersUrl = () => "/customers";
export const subscriptionsUrl = () => "/subscriptions";
export const rootUrl = () => "/";
export const profileUrl = () => "/profile";
export const mediaUrl = () => "/media";
export const emailChangeUrl = () => "/email-change-confirmation/:hash";
export const emailConfirmationUrl = () => "/email-confirmation/:hash";
export const registerUrl = () => "/register";
export const registerAthleteUrl = () => "/register-athlete";
// eslint-disable-next-line no-undef
export const assetUrl = name => `${process.env.REACT_APP_API_URL}/asset/${normalizeHost(window.location.host)}/${name}`;
export const competitionUrl = () => "/c/:clientNumber/:slug";
export const competitionAbsoluteUrl = plan => `https://${window.location.host}/c/${plan.user.clientNumber}/${plan.slug}`;
export const remindPasswordUrl = () => "/remind-password";
export const newPasswordUrl = () => "/new-password/:hash";
export const planTvAbsoluteUrl = tv => `https://${window.location.host}/tv/${tv}`;
export const planTvUrl = () => "/tv/:tv";
export const financeUrl = () => "/finance";
export const plansForSaleAbsoluteUrl = clientNumber => `https://${window.location.host}/s/${clientNumber}`;
export const recordingUrl = () => "/recording";
export const coachShopUrl = () => "/s/:clientNumber";
export const stripeLoginUrl = () => "/stripe-login/:accountId/:sessionId";
export const paymentSuccessUrl = () => "/payment-success/:hash";

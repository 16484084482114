import {
  Alert,
  Box, Button, Typography,
} from "@mui/material";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Loading } from "../../components/shared/other/index.js";
import { paymentSuccessGet } from "../../services/api/other.js";

export default ({ match }) => {
  const { t } = useTranslation();
  const { loading, error, query } = useQuery(paymentSuccessGet(match.params.hash));

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      {loading && <Loading />}
      <Box position="absolute">
        {!loading && !error && (
          <Alert severity="success">
            {t("subscription_extended")}
            <Typography variant="h6" style={{ fontWeight: 800, textAlign: "center", marginTop: 30 }}>
              <Link to="/"><Button>{t("get_back_to_main_page")}</Button></Link>
            </Typography>
          </Alert>
        )}
        {!loading && error && (
          <Alert severity="error">
            {t("subscription_error")}
            <Button onClick={() => query()}>{t("repeat_subscription")}</Button>
            <Typography variant="h6" style={{ fontWeight: 800, textAlign: "center", marginTop: 30 }}>
              <Link to="/"><Button>{t("get_back_to_main_page")}</Button></Link>
            </Typography>
          </Alert>
        )}
      </Box>
    </Box>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";

import { noteFilterGet } from "../../../services/api/note.js";
import { DataPanelTemplate } from "../../shared/templates/index.js";
import { NotesPanelItem } from "../index.js";

export default React.memo(({ bgFrom, overrideSm = false }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      disableAdding
      filterGet={noteFilterGet}
      dataPrefix="notes"
      limit="20"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      panelItem={NotesPanelItem}
      bgFrom={bgFrom}
      overrideSm={overrideSm}
    />
  );
});

import _ from "lodash";
import React from "react";

import { statisticDelete } from "../../../services/api/statistic.js";
import { models } from "../../../services/enums/index.js";
import { PreviewButton } from "../../shared/buttons/index.js";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other/index.js";
import { ModelItemTemplate } from "../../shared/templates/index.js";
import { StatisticPreviewDialog } from "../index.js";

export default ({
  item, highlight = [], callback, use,
}) => (
  <ModelItemTemplate
    inModal={_.isFunction(use)}
    header={<ItemHeaderWithHighlight name={item.name} highlight={highlight} />}
    footer={(
      <ItemFooter
        item={item}
        callback={callback}
        use={use}
        model={models.STATISTIC}
        modelDelete={statisticDelete}
        buttons={<PreviewButton args={[item._id]} previewDialog={StatisticPreviewDialog} />}
      />
)}
  />
);

import { Equalizer, Note } from "@mui/icons-material";
import {
  Box, Paper, Typography,
} from "@mui/material";
import React from "react";

import { entryDelete } from "../../../services/api/entry.js";
import { intervals, models } from "../../../services/enums/index.js";
import { NoteEntryPreviewDialog } from "../../note/index.js";
import { ScoreEntryPreviewDialog } from "../../score/index.js";
import { ModelActionButton, PreviewButton } from "../../shared/buttons/index.js";

export default React.memo(({
  entry, plan, callback, canEdit = true, isShared = false,
}) => {
  const planIsNotCompetition = () => plan.interval !== intervals.COMPETITION;

  return (
    <Paper style={{
      padding: 10, width: "calc(100% - 1px)", marginTop: 4, margin: 2,
    }}
    >
      <Typography
        variant="body1"
        style={{
          width: "100%", wordBreak: "break-word", fontWeight: 600,
        }}
      >
        {entry.title}
      </Typography>
      <Typography variant="caption" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
        {entry.content}
      </Typography>
      <Box flexBasis="100%" flexGrow="1" />
      <Box style={{ marginTop: 10 }} display="flex" flexWrap="wrap">
        <Box width="100%" display="flex" flex="1" justifyContent="flex-end">
          <>
            {entry.isScore && !entry.isInfo && (
            <PreviewButton
              args={[entry, plan, 0, canEdit, isShared]}
              previewDialog={ScoreEntryPreviewDialog}
              icon={Equalizer}
              tooltipTitle="scores"
            />
            )}
            {planIsNotCompetition() && !isShared && !entry.isInfo && (
            <PreviewButton
              args={[entry._id]}
              previewDialog={NoteEntryPreviewDialog}
              icon={Note}
              tooltipTitle="notes"
            />
            )}
          </>
          <ModelActionButton
            type="duplicate"
            variant="iconbutton"
            model={models.ENTRY}
            tooltip="create_duplicate"
            modelData={entry}
            fontSize="inherit"
            buttonSize="small"
            callback={callback}
          />
          {canEdit && (
          <ModelActionButton
            type="edit"
            variant="iconbutton"
            model={models.ENTRY}
            tooltip="edit"
            modelId={entry._id}
            fontSize="inherit"
            buttonSize="small"
            callback={callback}
          />
          )}
          {canEdit && (
          <ModelActionButton
            remove={entryDelete}
            modelId={entry._id}
            fontSize="inherit"
            buttonSize="small"
            callback={callback}
          />
          )}
        </Box>
      </Box>
    </Paper>
  );
});

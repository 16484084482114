import {
  Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { fileDataPost } from "../../../../services/api/file.js";
import { apiAction } from "../../../../services/api/index.js";
import { useOpenCloseControl } from "../../../../services/hooks/index.js";
import { FormDialogActions, ImageCropper, Loading } from "../../other/index.js";

export default React.memo(({
  id, route, actions, callback, width = 1232,
}) => {
  const imageCropperActions = {};
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isOpen, handleOpen, handleClose } = useOpenCloseControl();
  const Mutation = useMutation(apiAction);
  const [loading, setLoading] = React.useState(false);

  actions.open = () => handleOpen();

  const isLoading = () => Mutation.loading || loading;

  const save = async () => {
    setLoading(true);
    const fileData = await imageCropperActions.getImage();

    if (_.isNil(fileData)) {
      setLoading(false);

      return;
    }

    const FileResult = await Mutation.mutate(...fileDataPost({
      data: fileData,
    }));

    if (FileResult.error) {
      enqueueSnackbar(t(FileResult.payload.message), { variant: "error" });
    } else {
      const UserResult = await Mutation.mutate(...route({
        id,
        fileId: FileResult.payload.data._id,
      }));
      if (UserResult.error) {
        enqueueSnackbar(t(UserResult.payload.message), { variant: "error" });
      } else {
        handleClose();
        callback();
        enqueueSnackbar(t("saved_successfully"), { variant: "success" });
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xl"
      open={isOpen()}
      onClose={handleClose}
    >
      <DialogTitle>{t("background")}</DialogTitle>
      <DialogContent>
        {isLoading() && <Loading />}
        <ImageCropper actions={imageCropperActions} cropShape="rect" imgWidth={width} imgHeight={300} cropperHeight={350} />
      </DialogContent>
      <FormDialogActions close={handleClose} submit={save} />
    </Dialog>
  );
});

import {
  AccountCircle, AttachMoney, CameraAlt, CreditCard, Dashboard, Group, LibraryBooks, MoreVert, Score, Videocam, ViewModule,
} from "@mui/icons-material";
import {
  Box, Divider, IconButton, ListItemIcon, Menu, MenuItem,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { featureFlags } from "../../../../services/enums/index.js";
import { useAnchorControl, useFeatureFlag, useSizeStyle } from "../../../../services/hooks/index.js";
import {
  customersUrl, entriesUrl, financeUrl, mediaUrl, plansUrl, profileUrl, recordingUrl, rootUrl, statisticsUrl, subscriptionsUrl,
} from "../../../../services/routes.js";
// import { MessageButton } from "../../../message";
import { CompetitionSignUpButton } from "../../../competition/index.js";
import { CustomerViewMenuItem } from "../../../customer/index.js";
import {
  LanguageSwitchButton, LoginButton, PlanTermsButton, ThemeSwitchButton,
} from "../../buttons/index.js";
import { Logout } from "../index.js";

export default React.memo(({
  competition = false, plan, callback, isCoach = false, isCustomer = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { select } = useSizeStyle({
    xs: true, sm: true, md: false, lg: false, xl: false, default: false,
  });
  const { anchorEl, setAnchor, removeAnchor } = useAnchorControl();
  const { isEnabled } = useFeatureFlag(featureFlags.PAYMENTS);
  const RecordingFlag = useFeatureFlag(featureFlags.RECORDING);

  const navigate = url => {
    removeAnchor();
    history.push(url);
  };

  return (
    <>
      <IconButton size="large" aria-controls="header-menu-coach" aria-haspopup="true" onClick={setAnchor}>
        <MoreVert />
      </IconButton>
      <Menu
        id="header-menu-coach"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={removeAnchor}
      >
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          {competition && !_.isNil(plan) && plan.terms && <PlanTermsButton plan={plan} />}
        </Box>
        )}
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          {competition && !_.isNil(plan) && <CompetitionSignUpButton plan={plan} callback={callback} />}
        </Box>
        )}
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          {competition && !isCoach && !isCustomer && !_.isNil(plan) && <LoginButton />}
        </Box>
        )}
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          <LanguageSwitchButton />
          <ThemeSwitchButton />
          {/* {(isCoach || isCustomer) && <MessageButton />} */}
        </Box>
        )}
        {select() && (isCoach || isCustomer) && <Divider />}

        {isCoach && (
        <MenuItem onClick={() => navigate(rootUrl())}>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          {t("main_panel")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(plansUrl())}>
          <ListItemIcon>
            <ViewModule fontSize="small" />
          </ListItemIcon>
          {t("plans")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(entriesUrl())}>
          <ListItemIcon>
            <LibraryBooks fontSize="small" />
          </ListItemIcon>
          {t("entries")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(statisticsUrl())}>
          <ListItemIcon>
            <Score fontSize="small" />
          </ListItemIcon>
          {t("statistics")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(customersUrl())}>
          <ListItemIcon>
            <Group fontSize="small" />
          </ListItemIcon>
          {t("customers")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(subscriptionsUrl())}>
          <ListItemIcon>
            <CreditCard fontSize="small" />
          </ListItemIcon>
          {t("subscriptions")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(mediaUrl())}>
          <ListItemIcon>
            <CameraAlt fontSize="small" />
          </ListItemIcon>
          {t("media")}
        </MenuItem>
        )}

        {(isCoach || isCustomer) && RecordingFlag.isEnabled() && (
        <MenuItem onClick={() => navigate(recordingUrl())}>
          <ListItemIcon>
            <Videocam fontSize="small" />
          </ListItemIcon>
          {t("recording")}
        </MenuItem>
        )}

        {isCustomer && (
        <MenuItem onClick={() => navigate(rootUrl())}>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          {t("main_panel")}
        </MenuItem>
        )}

        {(isCoach || isCustomer) && <Divider />}
        {isCoach && isEnabled() && (
        <MenuItem onClick={() => navigate(financeUrl())}>
          <ListItemIcon>
            <AttachMoney fontSize="small" />
          </ListItemIcon>
          {t("finance")}
        </MenuItem>
        )}
        {(isCoach || isCustomer) && (
        <MenuItem onClick={() => navigate(profileUrl())}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          {t("my_profile")}
        </MenuItem>
        )}
        {(isCoach || isCustomer) && <CustomerViewMenuItem callback={removeAnchor} />}
        {(isCoach || isCustomer) && <Divider />}
        {(isCoach || isCustomer) && <Logout />}
      </Menu>
    </>
  );
});

import { Box } from "@mui/material";
import React, { memo, useState } from "react";
import { useQuery } from "react-fetching-library";

import { userPanelCustomerGet } from "../../../services/api/user.js";
import { BoxMessage, GenericError, Loading } from "../../shared/other/index.js";
import PlanBox from "./PlanBox/index.js";
import TopMenuPaper from "./TopMenuPaper/index.js";

export default memo(() => {
  const [plans, setPlans] = useState([]);
  const [atLeastOnePlan, setAtLeastOnePlan] = useState(false);
  const {
    loading, payload, error, status, query,
  } = useQuery(userPanelCustomerGet());
  const boxRef = React.useRef(null);

  React.useEffect(() => {
    setPlans(payload?.plans || []);

    if (payload && payload.plans && payload.plans.length > 0) {
      payload?.plans.forEach(plan => {
        if (!plan.customerHidden) {
          setAtLeastOnePlan(true);
        }
      });
    }
  }, [payload, loading, atLeastOnePlan]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box display="flex" flexWrap="wrap" width="100%" position="relative" ref={boxRef}>
      {loading && <Loading />}
      <TopMenuPaper plans={plans} reloadPlans={query} />
      {!atLeastOnePlan && <BoxMessage msg="no_plans_to_show" />}
      <Box position="relative" width="100%">
        {plans.map((plan, idx) => (plan.customerHidden ? null : <PlanBox key={idx} plan={plan} reloadPlans={query} />),
        )}
      </Box>
    </Box>
  );
});

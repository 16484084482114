import { Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import Highlighter from "react-highlight-words";

import { createUserDisplayName } from "../../../services/utils/index.js";

export default ({ item, highlight = [] }) => {
  const theme = useTheme();

  return (
    <Typography variant="caption" style={{ width: "100%" }}>
      <Highlighter
        highlightStyle={{ backgroundColor: theme.palette.action.selected }}
        searchWords={highlight}
        autoEscape
        textToHighlight={_.isNil(item.coach) ? createUserDisplayName(item, "userName", "userEmail") : item.coach.email}
      />
    </Typography>
  );
};

import {
  Box, Link, Paper, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { LogoCenterBox } from "../../components/shared/boxes/index.js";
import Form from "./_form.js";

export default () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" style={{ minHeight: 820, marginTop: 10, marginBottom: 10 }}>
      <Box position="absolute">
        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Paper style={{
            paddingLeft: 20, paddingRight: 20, paddingBottom: 10, width: 300,
          }}
          >
            <LogoCenterBox />
            <Box flex="1" display="flex" justifyContent="center">
              <Typography variant="h3" style={{ marginTop: 2, marginBottom: 20 }}>
                {_.startCase(_.toLower(t("register")))}
              </Typography>
            </Box>
            <Form />
            <Box flex="1" display="flex">
              <Typography variant="body2" style={{ marginTop: 20 }}>
                <Link href="/">{t("i_already_have_an_account")}</Link>
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

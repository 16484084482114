import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { competitionEntriesByPlanGet } from "../../../services/api/competition.js";
import { useSizeStyle } from "../../../services/hooks/index.js";
import { CompetitionCategorySelectField } from "../../shared/fields/index.js";
import { GenericError, Loading } from "../../shared/other/index.js";
import EventPeriodItem from "./_event-period-item.js";
import Leaderboard from "./_leaderboard.js";
import splitByDays from "./_split-by-days.js";

export default ({ plan }) => {
  const FlexLeftCol = useSizeStyle({
    xs: "1 0 100%", sm: "1 0 100%", md: "1 0 66%", lg: "1 0 66%", xl: "1 0 66%",
  });
  const padding = useSizeStyle({
    xs: {}, sm: {}, md: { paddingLeft: "5px" }, lg: { paddingLeft: "5px" }, xl: { paddingLeft: "5px" },
  });
  const [data, setData] = React.useState([]);
  const [category, setCategory] = React.useState(null);
  const {
    loading, error, payload, query,
  } = useParameterizedQuery(competitionEntriesByPlanGet);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload.entries)) {
      splitByDays(payload.entries.data, setData);
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError />;
  }

  return (
    <Box display="flex" flexWrap="wrap" marginTop="30px">
      <Box flex={FlexLeftCol.select()} position="relative" marginBottom="30px">
        {loading && <Loading />}
        <Typography variant="h4" color="default" style={{ wordBreak: "break-word", textAlign: "center" }}>
          {t("events")}
        </Typography>
        <CompetitionCategorySelectField
          form={{ planId: plan._id, competitionCategory: category }}
          isFieldError={() => false}
          fieldErrorMessage={() => ""}
          useOutsideForm
          size="small"
          setFieldValue={(field, value) => {
            setCategory(value);
            query(plan._id, value);
          }}
          style={padding.select()}
        />
        {data.map((entries, idx) => <EventPeriodItem key={`day_${idx}`} t={t} plan={plan} idx={idx} entries={entries} />)}
      </Box>
      {!loading && <Leaderboard plan={plan} category={category} />}
    </Box>
  );
};

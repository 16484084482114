import { Box, CircularProgress } from "@mui/material";
import React from "react";

import { Overlay } from "../index.js";

export default React.memo(({ size = 40, bgFrom }) => (
  <Overlay bgFrom={bgFrom}>
    <Box
      style={{
        position: "absolute",
        top: "calc(50% - 20px)",
        left: "calc(50% - 20px)",
      }}
      aria-label="loading"
    >
      <CircularProgress color="secondary" size={size} />
    </Box>
  </Overlay>
));

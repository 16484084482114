import { EventRepeat } from "@mui/icons-material";
import {
  Box,
  IconButton, Paper, TextField, Tooltip,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default ({
  onChange, right = 0, top = 0, color = "default", disableFuture = false,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);

  return (
    <>
      <Tooltip title={t("pick_date")} placement="top" disableTouchListener>
        <IconButton data-testid="dateselectbutton" color={color} onClick={() => setShow(!show)}>
          <EventRepeat fontSize="small" />
        </IconButton>
      </Tooltip>
      {show && (
        <Paper style={{
          overflow: "hidden", position: "absolute", zIndex: 9999, right, top,
        }}
        >
          <Box
            onClick={() => setShow(!show)}
            sx={{
              zIndex: -3,
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backdropFilter: "blur(5px)",
            }}
          />
          <StaticDatePicker
            disableFuture={disableFuture}
            showToolbar={false}
            componentsProps={{ actionBar: { actions: [] } }}
            value={date}
            onChange={val => {
              onChange(val);
              setDate(val);
              setShow(false);
            }}
            renderInput={args => (
              <TextField
                {...args}
              />
            )}
          />
        </Paper>
      )}
    </>
  );
};

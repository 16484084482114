import { differenceInCalendarDays } from "date-fns";
import _ from "lodash";

import { fixed, intervals } from "../../../services/enums/index.js";
import { createFuncDef } from "../../../services/utils/index.js";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      order: data.order,
      planId: data.planId,
      planName: data.plan.name,
      planInterval: data.plan.interval,
      date: new Date(data.date),
      isDeadline: _.isNil(data.isDeadline) ? false : data.isDeadline,
      deadlineDate: new Date(data.deadlineDate),
      title: data.title,
      content: data.content,
      comment: data.comment,
      isScore: _.isEqual(data.plan.interval, intervals.COMPETITION) ? true : data.isScore,
      isScoreHidden: _.isNil(data.isScoreHidden) ? false : data.isScoreHidden,
      isHidden: _.isNil(data.isHidden) ? false : data.isHidden,
      scoreUnit: _.isNil(data.scoreUnit) ? "" : data.scoreUnit,
      statisticId: data.statistic ? data.statistic._id : "",
      statisticName: data.statistic ? data.statistic.name : "",
      media: !_.isNil(data.media) && !_.isEmpty(data.media[0]) ? data.media : [],
      day: differenceInCalendarDays(new Date(data.date), new Date(fixed.STATIC_BLOCK_START_TIME)) + 2,
      competitionCategory: _.isNil(data.competitionCategory) ? null : data.competitionCategory,
      isTieBreak: _.isNil(data.isTieBreak) ? false : data.isTieBreak,
      tieBreakUnit: _.isNil(data.tieBreakUnit) ? "" : data.tieBreakUnit,
      tieBreakDesc: _.isNil(data.tieBreakDesc) ? "" : data.tieBreakDesc,
      isConfirmationRequired: _.isNil(data.isConfirmationRequired) ? false : data.isConfirmationRequired,
      isTimeCap: _.isNil(data.isTimeCap) ? false : data.isTimeCap,
      isInfo: _.isNil(data.isInfo) ? false : data.isInfo,
    });
  }),
];

import React from "react";
import { useTranslation } from "react-i18next";

import { entryFilterGet } from "../../../services/api/entry.js";
import { models } from "../../../services/enums/index.js";
import { DataPanelTemplate } from "../../shared/templates/index.js";
import { EntriesPanelItem } from "../index.js";

export default React.memo(({ use, bgFrom }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      use={use}
      filterGet={entryFilterGet}
      dataPrefix="entries"
      addTooltip="add_entry"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      filterDescs={{
        dateFrom: { label: t("date_from"), type: "date", value: null },
        dateTo: { label: t("date_to"), type: "date", value: null },
        plan: { label: t("plan"), type: "plan", value: null },
      }}
      model={models.ENTRY}
      panelItem={EntriesPanelItem}
      bgFrom={bgFrom}
    />
  );
});

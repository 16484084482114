import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { userStripeAuthorizeGet } from "../../../services/api/user.js";

const StripeConnectButton = React.memo(() => {
  const { t } = useTranslation();
  const {
    loading, payload, query,
  } = useQuery(userStripeAuthorizeGet(), false);

  useEffect(() => {
    if (!loading && !_.isNil(payload)) {
      window.location.href = payload.url;
    }
  }, [payload, loading]);

  return (
    <LoadingButton variant="contained" loading={loading} onClick={() => query()}>{t("connect_with_stripe")}</LoadingButton>
  );
});

export default StripeConnectButton;

import {
  Box, Link, Paper, Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { LogoCenterBox } from "../../components/shared/boxes/index.js";
import PasswordFormBox from "./_password-form-box.js";

export default ({ match }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" style={{ minHeight: 340 }}>
      <Box position="absolute">
        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Paper style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            width: 300,
          }}
          >
            <LogoCenterBox />
            <Typography variant="h5" textAlign="center" style={{ marginBottom: 15 }}>{t("new_password")}</Typography>
            <PasswordFormBox passwordHash={match.params.hash} />
            <Box flex="1" display="flex">
              <Box flex="1">
                <Typography variant="body2" style={{ marginTop: 20 }}>
                  <Link href="/">{t("back")}</Link>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

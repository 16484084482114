/* eslint-disable camelcase */
import React from "react";
import { useTranslation } from "react-i18next";

import { userMeGet, userMePut } from "../../../../services/api/user.js";
import { useFormEngine } from "../../../../services/hooks/index.js";
import { onSuccessCallbacks } from "../../../../services/utils/index.js";
import { TextField } from "../../../shared/fields/index.js";
import { FormDialogTemplate } from "../../../shared/templates/index.js";
import formSchema from "./_form-schema.js";
import onLoadCallbacks from "./_on-load-callbacks.js";

const UserProfileLinkFormDialog = React.memo(({ actions, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, null, userMePut, userMeGet, {
    // Double floor is important here. It creates a nested json later.
    link__instagram: "",
    link__facebook: "",
    link__twitter: "",
    link__website: "",
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="links"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            label="Instagram"
            fieldName="link__instagram"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="Facebook"
            fieldName="link__facebook"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="Twitter"
            fieldName="link__twitter"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            label="your_website"
            fieldName="link__website"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    />
  );
});

export default UserProfileLinkFormDialog;
